import React from "react";
import {
  // Customizable Area Start
  Typography,
  Grid,
  Button,
  Box,
  AppBar,
  Toolbar,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import { Link, NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

import {
  FlixoLogo,
  FlixooLogo,
  whiteSearchBar,
  user,
  notification,
  bag,
} from "./assets";

import StayTuned from "./StayTuned.web";
interface Props {
  classes: any;
}

interface S {
  isMenuOpen: boolean;
  isSearchExpanded: boolean;
  isLinkNone: boolean
  openTunedBox: boolean;
}
// Customizable Area End

class AppHeaderSeller extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    // Customizable Area Start
    this.state = {
      isMenuOpen: false,
      isSearchExpanded: false,
      isLinkNone: false,
      openTunedBox: false
    };
    // Customizable Area End
  }

  // Customizable Area Start
  handleMenuToggle = () => {
    this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
  };
  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  }
  handleSearchExpand = () => {
    this.setState((prevState) => ({ isSearchExpanded: !prevState.isSearchExpanded }));
    if (this.state.isSearchExpanded) {
      this.setState({ isLinkNone: false })
    }
    else {
      this.setState({ isLinkNone: true })
    }
  };
  openStayTunedBox = () => {
    this.setState({
      isMenuOpen: false,
      openTunedBox: true
    });
  }
  closeTunedBox = () => {
    this.setState({
      openTunedBox: false
    })
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { isMenuOpen, isSearchExpanded } = this.state;
    const isProductDescriptionRoute = location.pathname.includes("ProductDescription");
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <section>
          <AppBar className={classes.AppBarBox}>
            <Toolbar>
              <Grid container spacing={2}>
                {location.pathname === '/' ?
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} style={{ width: '95%', margin: '0 auto' }}>
                        <Button className={classes.appLogo} component={Link} to={'/SellerDashboard'}>
                          <img src={FlixoLogo} alt="" />
                        </Button>
                        <Box className={classes.HeaderGetStartedBtn}>
                          <Button className={classes.appLogo} style={{ backgroundColor: '#F5EA16' }} component={Link} to={'/GetStartedSignup'}>get started</Button>
                        </Box>
                      </Box>
                    </Grid> </>
                  : <> <Grid item xs={3} lg={2}>
                    <Box>
                      <Button className={classes.appLogo} component={Link} to={'/SellerDashboard'}>
                        <img src={FlixooLogo} alt="" />
                      </Button>
                    </Box>
                  </Grid>

                    <Grid item xs={9} lg={10}>
                      <Box className={classes.headerMenuRight}>
                        <div className={classes.mobileMenu}>
                          <label htmlFor="toggle-nav" className="menu-icon" onClick={this.handleMenuToggle}>
                            <MenuIcon />
                          </label>
                          <TextField type="checkbox" id="toggle-nav" />
                        </div>
                        <Box
                          className={`${classes.headerLinkBox} ${isMenuOpen ? 'checked' : ''}`}
                          sx={{ display: 'flex', alignItems: 'center', gridGap: '60px' }}>
                          <Box className={classes.headerButtonLinks}>
                            <Button className={classes.headerLink} component={Link} to={'/CreatorSignUpScreen'}>
                                Become a Content Creator
                            </Button>
                            <Button className={classes.headerLink}
                              onClick={this.openStayTunedBox}>
                                Invite friends
                            </Button>
                            <StayTuned openTunedBox={this.state.openTunedBox} closeTunedBox={this.closeTunedBox} />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>}
              </Grid>
            </Toolbar>
          </AppBar>
        </section>
      </>
      // Customizable Area End
    );
  }
}


// Customizable Area Start
const styles = (theme: any) => ({
  AppBarBox: {
    height: '96px',
    backgroundColor: "#000000",
    '& .MuiToolbar-regular': {
      margin: 'auto 0',
    },
    '& #toggle-nav': {
      display: 'none',
    },
    '& .menu-icon': {
      display: 'none'
    },
    '& .HeaderGetStartedBtn': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down(1400)]: {
      height: '70px',
      '& #toggle-nav': {
        display: 'block',
      },
      '& .menu-icon': {
        display: 'block',
      },
      '& #toggle-nav:checked ~ .headerLinkBox': {
        left: '0%',
      }
    },
  },
  headerLinkBox: {
    display: 'flex',
    gridGap: '50px',
    transition: 'left 0.3s ease',
    '& a': {
      color: 'white',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.3px',
      textTransform: 'initial',
      opacity: '0.8',
      fontFamily: 'Poppins-Medium',
    },
    [theme.breakpoints.down(1400)]: {
      position: 'absolute' as const,
      top: '67px',
      left: '-106%',
      backgroundColor: '#000000',
      width: '100%',
      height: ' 100vh',
      padding: '20px',
      transition: '.3s ease',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '&.checked': {
        left: '0%',
        padding: '0px',
      },
      '& a': {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      left: '-111%',
      top: '63px',
    }
  },
  appLogo: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
      '& img': {
        maxHeight: '48px',
      },
    },
  },
  headerButtonLinks: {
    display: 'flex',
    gridGap: '10px',
    height: '32px',
    [theme.breakpoints.down(1400)]: {
      flexDirection: 'column' as const,
      justifyContent: 'center',
    },
  },
  headerMenuRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridGap: '30px',
    height: '100%',
    [theme.breakpoints.down(1400)]: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      gridGap: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      gridGap: '10px',
    },
  },
  mobileMenu: {
    [theme.breakpoints.down(1400)]: {
      width: '34px',
      height: '34px',
      order: 5 as const,
      '& svg': {
        width: '34px',
        height: '34px',
      },
      '& .MuiFormControl-root': {
        opacity: '0',
      },
    },
  },
  HeaderGetStartedBtn: {
    '& a': {
      fontFamily: 'Poppins-Regular',
      width: '107px',
      height: '32px',
      maxWidth: '100%',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.3px',
      textTransform: 'capitalize',
    },
  },
  // Define styles for the collapsed search bar
  activeLink: {
    color: '#ffffff !important' as const,
    fontWeight: 600,
    opacity: '1 !important' as const,
  },
  headerLink: {
    color: '#FFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px', /* 114.286% */
    letterSpacing: '0.3px',
    textTransform: 'none' as const
  }
});

export default withStyles(styles)(AppHeaderSeller);
// Customizable Area End