import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Button, InputLabel, Select, MenuItem, FormControl} from "@material-ui/core";
import { arrowLeftIc, FlixooIcon, instagramIcon, likeIcon, noRecordFound, shareNewIcon, youtubeIcon } from "./assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import CreatorMarketPlaceController, { Props } from "./CreatorMarketPlaceController";
import { arrowDownIcon } from "../../postcreation/src/assets";
import { searchModalIcon } from "../../LiveStreaming/src/assets";
import '../assets/css/styleSeller.css';
import { SnackBarAlert } from "../../../components/src/SnackBarAlert.web";
import Loader from "../../../components/src/Loader.web";
import { profileIcon } from "../../chat/src/assets";
// Customizable Area End


export default class CreatorMarketPlace extends CreatorMarketPlaceController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderGenderFilter = () => {
        return (
            <FormControl variant="outlined" className="formControlStyle" >
                <InputLabel id="gender-select" className="inputLabel filterTypeTxt">Gender</InputLabel>
                <Select
                    className={`selectStyle genderStyle`}
                    labelId="gender-select"
                    id="gender-select"
                    data-test-id="genderSelectTestId"
                    value={this.state.gender}
                    onChange={this.handleGenderChange}
                    variant="outlined"
                    label="Gender"
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            style: selectStylePaper
                        },
                    }}
                >
                    <MenuItem className="accountLabel" value="None">None</MenuItem>
                    <MenuItem className="menuItem" value="Male">Male</MenuItem>
                    <MenuItem className="menuItem" value="Female"> Female</MenuItem>
                    <MenuItem className="menuItem" value="Other"> Other</MenuItem>
                </Select>
            </FormControl>
        )
    };

    renderFollowersFilter = () => {
        return (
            <FormControl variant="outlined" className="formControlStyle" >
            <InputLabel id="followers-select" className="inputLabel filterTypeTxt">Followers</InputLabel>
            <Select
                className={`selectStyle followerStyle`}
                labelId="followers-select"
                id="followers-select"
                data-test-id="followerSelectTestId"
                value={this.state.followers}
                onChange={this.handleFollowerChange}
                variant="outlined"
                label="Gender"
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: selectStylePaper
                    },
                }}
            >
                    {this.rangeFollowerFilter.map((item: { label: string, minFollowers: number, maxFollowers: number | null }) => (
                        <MenuItem className="menuItem" value={item.label}>{item.label}</MenuItem>
                    ))}
            </Select>
        </FormControl>
        )
    };

    renderSpecialityFilter = () => {
        return (
            <FormControl variant="outlined" className="formControlStyle" >
            <InputLabel id="speciality-select" className="inputLabel filterTypeTxt">Speciality</InputLabel>
            <Select
                className={`selectStyle specialityStyle`}
                labelId="speciality-select"
                id="speciality-select"
                data-test-id="specialitySelectTestId"
                value={this.state.speciality}
                onChange={this.handleSpecialityChange}
                variant="outlined"
                label="Gender"
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: selectStylePaper
                    },
                }}
            >
                <MenuItem className="accountLabel" value={0}>None</MenuItem>
                {this.state.categoriesData.map((item)=>(
                    <MenuItem className="accountLabel"value={item.attributes.id}>{item.attributes.name}</MenuItem>
                ))}

            </Select>
        </FormControl>
        )
    };

    renderPriceFilter = () => {
        return (
            <FormControl variant="outlined" className="formControlStyle" >
            <InputLabel id="price-select" className="inputLabel filterTypeTxt">Price</InputLabel>
            <Select
                className={`selectStyle priceStyle`}
                labelId="price-select"
                id="price-select"
                data-test-id="priceSelectTestId"
                value={this.state.price}
                onChange={this.handlePriceChange}
                variant="outlined"
                label="Gender"
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: selectStylePaper
                    },
                }}
            >
                    {this.rangePriceFilter.map((item: { label: string, minPr: number, maxPr: number | null }) => (
                        <MenuItem className="menuItem" value={item.label}>{item.label}</MenuItem>
                    ))}
            </Select>
        </FormControl>
        )
    };

    renderLocationFilter = () => {
        return (
            <FormControl variant="outlined" className="formControlStyle" >
            <InputLabel id="location-select" className="inputLabel filterTypeTxt">Location</InputLabel>
            <Select
                className={`selectStyle locationStyle`}
                labelId="location-select"
                id="location-select"
                data-test-id="locationSelectTestId"
                onChange={this.handleLocationChange}
                value={this.state.location}
                label="Gender"
                variant="outlined"
                MenuProps={{
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom',
                    },
                    transformOrigin: {
                        horizontal: 'left',
                        vertical: 'top',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                        style: selectStylePaper
                    },
                }}
            >
                <MenuItem className="menuItem" value={this.state.locationData}>{this.state.locationData}</MenuItem>
            </Select>
        </FormControl>
        )
    };

    renderFilterView = () => {
        return (
            <Box className="filterBoxContainer">
                <Box className="filterBox">
                    <Typography className="filterTxt">Filter:</Typography>
                    <Box className="filterStatusBox">
                        {this.renderLocationFilter()}
                        {this.renderGenderFilter()}
                        {this.renderFollowersFilter()}
                        {this.renderSpecialityFilter()}
                        {this.renderPriceFilter()}
                    </Box>
                </Box>
                <Box className="searchBoxContainer">
                    <SearchStyleWrapper className="inventorySearch">
                        <img src={searchModalIcon} alt="search" />
                        <input type="text" placeholder={"Search"} data-test-id="inventorySearchTestID" />
                    </SearchStyleWrapper>
                </Box>
            </Box>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { creatorMarketData, isCreatorResponse } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div service-create-main-div">
                <div className="dashboard-outer" data-test-id="creatorProfileMainContainer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={"profile"}
                            isOpen={this.state.isOpen}
                            data-test-id="creatorSidebar"
                            closeModal={() => this.toggleDrawer()}
                            activetabName="CreatorMarketplace"
                        />
                        <CreatorMarketPlaceWrapper>
                        <Loader loading={this.state.appLoader} />
                        <SnackBarAlert data-test-id="snackBarCloseTestId" snackBarProps={this.state.snackBarStates} handleCloseSnack={this.handleCloseSnack} />
                            <Box className="marketPlaceHeaderBox">
                                <Box className="titleBox">
                                    <button className="backIconButton" onClick={()=> this.navigateToPath("SellerDashboard")}>
                                        <img src={arrowLeftIc} alt="left arrow" />
                                    </button>
                                    <Typography className="marketPlaceTitle">Creator Market Place</Typography>
                                </Box>
                            </Box>

                            <Box className="middleHeaderBox">
                                <Box className="socialDropDown">
                                    <Typography className="socialDropTxt">Instagram</Typography>
                                    <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                                </Box>
                            </Box>

                            {this.renderFilterView()}

                            <Box className="creatorMainContainer">
                                {isCreatorResponse && (
                                    creatorMarketData.length > 0 ? creatorMarketData.map((item, cardIdx) => {
                                        let profilePic = item?.attributes?.profile_photo;
                                        let marketingPrice = item?.attributes?.marketing_price;
                                        let creatorName = item?.attributes?.full_name;
                                        let followers = item?.attributes?.followers;
                                        let adderess = item?.attributes?.address.data;
                                        let fullAddress = adderess === null ? "" : `${adderess.attributes.city}, ${adderess.attributes.country}`;
                                        let igUrl = item?.attributes?.instagram_url;
                                        let youtubeUrl = item?.attributes?.youtube_url
                                        return (
                                            <Box className="cardMainBox" key={cardIdx} >
                                                <Box className="imageBox">
                                                    <Box className="topRightBox">
                                                        <Box className="iconMainContainer">
                                                            <Box className="iconBox pointer">
                                                                <img src={likeIcon} alt="heartIcon" />
                                                            </Box>
                                                            <Box className="iconBox pointer">
                                                                <img src={shareNewIcon} alt="shareIcon" />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="creatorImgBox" data-test-id={`creatorCardTestId${cardIdx}`} onClick={() => this.navigateToPath("ViewCreatorProfile", item.id)}>
                                                        <img src={profilePic !== null ? profilePic : profileIcon} alt="" className="creatorImg" />
                                                    </Box>
                                                    <Box className="bottomBox">
                                                        <Box className="priceBox">
                                                            <Typography className="priceText">
                                                                Marketing Price : <span className="boldTxt">₹{marketingPrice}</span>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            <Box className="creatorInfoBox" >
                                                <Box className="creatorDetail">
                                                    <Typography className="upperText">
                                                        {creatorName}
                                                    </Typography>
                                                    <Typography className="upperText">
                                                        {this.followersCount(followers)}
                                                    </Typography>
                                                </Box>
                                                <Box className="creatorDetail lowerBox">
                                                    <Typography className="lowerText">
                                                        {fullAddress}
                                                    </Typography>
                                                    <Typography className="lowerText">
                                                        Followers
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box className="creatorSocialBox">
                                                <Box className="socialIconBox">
                                                    { youtubeUrl !== null && <img src={youtubeIcon} alt="" className="socialIcons" onClick={()=> this.handleOpenLink(youtubeUrl)} data-test-id={`youtubeLinkTestId${cardIdx}`} /> }
                                                    { igUrl !== null && <img src={instagramIcon} alt="" className="socialIcons" onClick={()=> this.handleOpenLink(igUrl)}  /> }
                                                    <img src={FlixooIcon} alt="" className="socialIcons" onClick={()=> this.navigateToPath("CreatorProfile", item.id)} />
                                                </Box>
                                                <Box className="messageBtnBox">
                                                    <Button className="messageButton" onClick={()=> this.navigateToPath("SellerChatScreen")}>Message</Button>
                                                </Box>
                                            </Box>
                                            </Box>
                                        )
                                    }) :
                                        <Box className="noCreatorBox">
                                            <img src={noRecordFound} alt="" className="noDataImg" />
                                        </Box>
                                )
                                }
                            </Box>
                        </CreatorMarketPlaceWrapper>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CreatorMarketPlaceWrapper = styled(Box)({
    paddingTop: "33px",
    paddingLeft: "23px",
    paddingRight: "54px",
    width: "100%",
    "& .titleBox": {
        display: "flex",
        alignItems: "center",
        height: "24px"
    },
    "& .marketPlaceTitle": {
        fontWeight: 600,
        fontSize: "16px",
        color: "#444444CC"
    },
    "& .filterBoxContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "wrap",
        gap: "20px"
    },
    "& .filterBox": {
        display: "flex",
        alignItems: "center",
    },
    "& .filterStatusBox": {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        flexWrap: "wrap",
    },
    "& .filterTxt": {
        color: "#444444",
        fontSize: "14px",
        fontWeigt: 400,
        marginRight: "10px"
    },
    "& .filterInputBox": {
        border: "1px solid #00000080",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "112px",
        padding: "4px 14px"
    },
    "& .filterTypeTxt": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#64748B"
    },
    "& .downIcon": {
        width: "24px",
        height: "24px",
    },
    "& .creatorMainContainer": {
        marginTop: "40px",
        display: "flex",
        gap: "30px",
        flexWrap: "wrap",
        maxHeight: "900px",
        overflowX: "auto",
        scrollbarWidth:"none",
        "@media(max-width:492px)": {
            display: "flex",
            justifyContent: "center"
        },
    },
    "& .noCreatorBox":{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column",
        width:"50%",
        height:"300px",
    },
    "& .noCreator":{
        fontSize:"20px",
        marginLeft:"10px"
    },
    "& .noDataImg":{
        width:"200px"
    },
    "& .creatorMainContainer::-webkit-scrollbar ": {
        display: "none",
      },
    "& .cardMainBox": {
        maxWidth: "190px",
        height: "346px",
        WebkitAnimation: 'fadeIn 1s',
        MozAnimation: 'fadeIn 1s',
        OAnimation: 'fadeIn 1s',
        msAnimation: 'fadeIn 1s',
        animation: 'fadeIn 1s',
    },
    "& .creatorImgBox":{
        width:"100%",
        maxWidth: "190px",
        height: "264px",
        background:"#e4e6e7",
        borderRadius: "10px",
    },
    "& .creatorImg": {
        borderRadius: "10px",
        cursor:"pointer",
        width:"100%",
        maxWidth: "190px",
        height: "264px",

    },
    "& .creatorInfoBox": {
        marginTop: "9px"
    },
    "& .creatorDetail": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .lowerText": {
        fontSize: "10px",
        fontWeight: 400,
        color: "#2E2E2E",
        lineHeight: "16px"
    },
    "& .upperText": {
        fontSize: "12px",
        fontWeight: 600,
        color: "#2E2E2E",
        lineHeight: "16px"
    },
    "& .creatorSocialBox": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "8px",
        alignItems: "center"
    },

    "& .socialIcons": {
        width: "20px",
        marginRight: "6px",
        cursor: "pointer"
    },
    "& .pointer": {
        cursor: "pointer"
    },
    "& .messageButton": {
        width: "79px",
        textTransform: "none",
        border: "1px solid yellow",
        fontSize: "12px",
        fontWeight: 500,
        borderRadius: "50px",
        padding: "3px 16px"
    },
    "& .middleHeaderBox": {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "20px"
    },
    "& .socialDropDown": {
        display: "flex",
        justifyContent: "center"
    },
    "& .socialDropTxt": {
        fontWeight: 600,
        fontSize: "16px"
    },
    "& .imageBox": {
        position: "relative",
    },
    "& .bottomBox": {
        position: "absolute",
        bottom: "0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "43px",
        background: "#1313134A",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
    },
    "& .priceText": {
        fontWeight: 500,
        fontSize: "12px",
    },
    "& .boldTxt": {
        fontWeight: 600
    },
    "& .priceBox": {
        maxWidth: "164px",
        height: "25px",
        backgroundColor: "#BAFFBE",
        padding: "3px 16px",
        borderRadius: "4px"
    },
    "& .topRightBox": {
        position: "absolute",
        top: "10px",
        right: "10px"
    },
    "& .iconMainContainer": {
        display: "flex",
        gap: "8px"
    },
    "& .iconBox": {
        width: "24px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00000099",
        borderRadius: "50px"
    },
    "& .backIconButton": {
        border: "none",
        outline: "none",
        background: "white",
        padding: "0px",
        marginRight: "16px",
        cursor:"pointer"
    },
    "& .selectStyle": {
        borderRadius: "50px",
        minWidth: "112px",
        height: "36px",
        fontWeight: 500,
        fontSize: "14px",
        color: "#64748B",
        "& .MuiOutlinedInput-notchedOutline ": {
            borderColor: "#00000080"
        }
    },
    "& .genderStyle":{
        minWidth: "103px",
    },
    "& .followerStyle":{
        minWidth: "117px",
    },
    "& .specialityStyle":{
        minWidth: "121px",
    },
    "& .priceStyle":{
        minWidth: "86px",
    },
    "& .inputLabel": {
        transform: "translate(14px, 11px) scale(1) !important",
    },
    "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75) !important",
    },
});

const SearchStyleWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px",
    gap: "12px",
    borderRadius: "50px",
    border: "1px solid #CAC8C8",
    "& .inventorySearch": {
        maxWidth: "351px",
        width: "100%",
        height: "45px",
    },
    "& img": {
        cursor: "pointer"
    },
    "& input": {
        border: "none",
        outline: "none",
        width: "100%",
    },
});

const selectStylePaper = {
    border: '2px solid rgba(255, 255, 255, 0.50)',
    borderRadius: '0px 0px 12px 12px',
    borderTop: 'none',
    "& .MuiSvgIcon-root":{
        fill:"white"
    }
};
// Customizable Area End