// Customizable Area Start
import React from "react";

import {
    Button,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Tabs,
    Tab,
    Box,
    Typography,
    Grid,
    styled
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles, StyleRules } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import '../assets/css/style.css'
import '../assets/css/styleSeller.css'
import TextField from '@material-ui/core/TextField';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, withRouter } from "react-router-dom";
import StayTuned from "../../../components/src/StayTuned.web";

import {
    profileImg,
    star,
    bargainCardImg,
    postCardImg,
    AddYellowIc,
    filterIc,
    searchIcon,
    archiveIc,
    likeIc,
    rightArrow,
    shareIc,
    CloseIc,
    bannerImageBlankSeller,
    EditYellowIc,
    archiveBlankIc,
    noDataFound
} from "./assets";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import StoryListing from "../../../components/src/CustomStoryListing.web";

const baseURL = require("../../../framework/src/config.js").baseURL

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}
// Customizable Area End

import CustomisableUserProfilesController, {
    Props,
} from "./CustomisableUserProfilesController";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import { StoryUploaders } from "./CustomisableSellerProfilesControllerWeb";

class UserProfiles extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractBuyerData = () => {
        let userName = ""
        let profilephoto = ""
        let backgroundphoto = ""
        let my_bio = ""
        if (this.state.buyerProfileData) {
            userName = truthyValue(this.state.buyerProfileData.attributes.full_name)
            profilephoto = truthyValue(this.state.buyerProfileData.attributes.profile_photo)
            backgroundphoto = truthyValue(this.state.buyerProfileData.attributes.background_photo)
            my_bio = truthyValue(this.state.buyerProfileData.attributes.my_bio)
        }
        return { userName, profilephoto, backgroundphoto, my_bio }
    }

    sliderProps = {
        className: "slider variable-width",
        // dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    };

    slidder() {
        const { navigation, classes } = this.props;
        const { subCategoryData, wishListProduct } = this.state;

        return (
            <Slider {...this.sliderProps}>
                {subCategoryData.map((item: any, index: number) => (
                    <Box
                        className={classes.suggestionBox}
                        key={index}
                        data-testid='suggestionBox'
                    >
                        {
                            this.checkProductStatus(wishListProduct, parseInt(item.id))
                                ? (
                                    <Box className={classes.archiveBox} data-test-id={`removeWishItem-${index}`} onClick={() => this.deleteProductItem(wishListProduct, parseInt(item.id))}>
                                        <img src={archiveIc} style={{ height: '16px', width: '16px' }} />
                                    </Box>
                                ) : (
                                    <Box className={classes.archiveBox} data-test-id={`addWishItem-${index}`} onClick={() => this.updateWishListItem(item.id, item.type)}>
                                        <img src={archiveBlankIc} style={{ height: '16px', width: '16px' }} />
                                    </Box>
                                )
                        }
                        <img src={item.attributes.product_images[0].url} data-test-id={`imageDescriptionId-${index}`} style={{ height: '256px', maxWidth: '340px', width: '100%', borderRadius: '16px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover' }} onClick={() => this.handleProductDescription(item.id)} />
                        <Box className={classes.profileBox}>
                            <Box className={classes.profilePicture}>
                                <Avatar src={item.attributes.host_image} style={{ height: '40px', width: '40px' }} />
                            </Box>
                            <Box>
                                <Typography className={classes.profileTxt} style={{ fontWeight: 500 }} >{item.attributes.host_name}</Typography>
                                <Typography className={classes.profileTxt} style={{ color: "#A5A4A4" }} >{item.attributes.description}</Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Slider>
        )
    }

    orderStatus = (status: string) => {
        if (status === 'placed') {
            return ('Placed')
        } else if (status === 'shipped') {
            return ('Shipped')
        } else if (status === 'accepted') {
            return ('Accepted');
        } else if (status === 'declined') {
            return ('Cancelled');
        } else if (status === 'on_hold') {
            return ('On hold');
        }
    }

    orderData = (status: string, date: any) => {
        let oldDate = date.split('T')
        let finalDate = oldDate[0].split('-')
        if (status === 'placed' || status === 'shipped') {
            return (<><strong>Order date:</strong> {`${finalDate[2]}-${finalDate[1]}-${finalDate[0]}`}</>)
        }
    }

    renderStoryModal = () => {
        return (
            <StoryListing
                data-test-id="storyModalTestID"
                allStoryHeader={this.state.allStoryHeader}
                storyMuted={this.state.storyMuted}
                toggleMute={this.toggleMute}
                calculateHours={this.calculateHours}
                likeStory={this.likeStory}
                setSwiperRef={this.setSwiperRef}
                currentStoryIndex={this.state.currentStoryIndex}
                userStory={this.state.userStory}
                showNextButton={this.showNextButton}
                showPrevButton={this.showPrevButton}
                changeStory={this.changeStory}
                instaModal={this.state.instaModal}
                instaModalIndex={this.state.instaModalIndex}
                handleClose={this.handleClose}
                handleNext={this.handleNext}
                handlePrev={this.handlePrev}
                userID={this.state.BuyerDataId}
                storyId={this.handleStoryId}
                commentMessage={this.handleMessage}
                isMessageSent={""}   
            />
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { userName, profilephoto, backgroundphoto, my_bio } = this.abstractBuyerData()
        const {orderList, userStory} = this.state;
                // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div">
                 <div id="myref"></div>
                <section className="banner-section">
                    {backgroundphoto ?
                        <div className="banner-img-wrap">
                            <img src={backgroundphoto} alt="banner Img" />
                            <IconButton className="edit-icon">
                                <img src={EditYellowIc} alt="Edit" />
                                <input
                                    accept="image/*"
                                    id="upload-avatar-pic"
                                    type="file"
                                    className="upload"
                                    data-test-id="uploadFileBackground"
                                    onChange={this.handleBuyerBannerImage}
                                />
                            </IconButton>
                        </div>
                        :
                        <div className="banner-img-wrap no-bg">
                            <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                            <IconButton className="edit-icon">
                                <img src={AddYellowIc} alt="Edit" />
                                <input
                                    accept="image/*"
                                    id="upload-avatar-pic"
                                    type="file"
                                    className="upload"
                                    data-test-id="uploadFileBackground"
                                    onChange={this.handleBuyerBannerImage}
                                />
                            </IconButton>
                        </div>
                    }
                    <div className="profile-detail">
                        <div className="container">
                            <div className="profile-detail-inner">
                                <div className="profile-photo-wrap">
                                    <Avatar src={profilephoto} className="profile-photo">
                                    </Avatar>
                                    <IconButton className="edit-icon">
                                        {profilephoto ?
                                            <img src={EditYellowIc} alt="Edit" />
                                            :
                                            <img src={AddYellowIc} alt="Edit" />
                                        }
                                        <input
                                            accept="image/*"
                                            id="upload-avatar-pic"
                                            type="file"
                                            className="upload"
                                            onChange={this.handleBuyerProfileImage}
                                        />
                                    </IconButton>
                                </div>
                                <div className="profile-description-wrap">
                                    <div className="name-wrap">
                                        <span className="name">{userName}</span>
                                        <span className="review">
                                            4.5
                                            <i>
                                                <img src={star} alt="Review" />
                                            </i>
                                        </span>
                                    </div>

                                    <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />

                                    <div className="following-followers-wrap">
                                        <div className="title-number-wrap" 
                                            data-test-id="followers"
                                            onClick={() => this.openStayTunedBox()}>
                                            <span className="title">Followers</span>
                                            <span className="number">0</span>
                                        </div>
                                        <div className="title-number-wrap" 
                                            data-test-id="following"
                                            onClick={() => this.handleOpenModalFollowing()}>
                                            <span className="title">Following</span>
                                            <span className="number">{this.state.sallerFollowingData.meta.total_following}</span>
                                        </div>
                                        <div className="btn-wrap">
                                            <Button className="custom-button primary-yellow small" component={Link} to={'/UserProfileEdit'}>
                                                Edit Profile
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="user-profile-section">
                    <div className="container">
                        <div className="bio-wrap">
                            <span className="title">Bio</span>
                            <span className="bio">
                                {my_bio}
                            </span>
                        </div>
                        <div className="stories-highlights-wrap">
                            <div className="inner-wrap">
                                {userStory.map((item: StoryUploaders, index: number) => {
                                    return (
                                        <>
                                            {
                                                <div className="story" data-test-id={`openStoryTestID${index}`} onClick={() => { this.handleClickOpen(index) }}>
                                                    {item.attributes.media.type === "image" ?
                                                        <Avatar src={item.attributes.media.url} data-test-id={`imgUserTestID${index}`} className="profile-photo" />
                                                        : <video src={item.attributes.media.url}
                                                            autoPlay={false}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            muted={this.state.storyMuted}
                                                            playsInline />
                                                    }
                                                </div>
                                            }
                                        </>
                                    )
                                })}
                            </div>
                            {this.state.allStoryHeader.length ? this.renderStoryModal() : <></>}
                        </div>
                    </div>
                </section>
                <section className="tabs-section">
                    <div className="container">
                        <div className="tabs-wrap">
                            <Tabs
                                value={this.state.tabValue}
                                onChange={this.handleChange}
                                className="custom-tabs-list"
                            >
                                <Tab data-test-id="yourOrder" label="Your orders" disableRipple />
                                <Tab data-test-id="bargain" label="Bargain" disableRipple />
                                <Tab label="On-order" disableRipple />
                                <Tab label="Post" disableRipple />
                            </Tabs>
                            <div className="tabs-body">
                                {this.state.tabValue === 0 && (
                                    <div className="tabs-content">
                                        <form onSubmit={(e) => this.orderSearchSubmit(e)}>
                                            <div className="search-wrap">
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <img src={searchIcon} alt="Search" />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton className="filter-ic"
                                                                    onClick={() => this.handleOpenModalFilter()}>
                                                                    <img src={filterIc} alt="Search" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    data-test-id="searchFilter"
                                                    onChange={this.handleChangeOrderSearch}
                                                    variant="outlined"
                                                    placeholder="Search all Orders"
                                                    className="search-input"
                                                />
                                            </div>
                                        </form>
                                        <div className="inner-tab-content">
                                            {orderList.length > 0 ?

                                                <div className="inner-tab-wrap">
                                                    <div className="your-orders-cards-wrap">
                                                        {orderList.map((item: any) => (
                                                            <div className="your-orders-card" key={item.id}>
                                                                <div className="inner-wrap">
                                                                    <div className="status-wrap">
                                                                        <div className="content-wrap">
                                                                            <span className="title">{this.orderStatus(item.attributes.status)}</span>
                                                                            <span className="detail">
                                                                                {this.orderData(item.attributes.status, item.attributes.placed_at)}
                                                                            </span>
                                                                        </div>
                                                                        <span
                                                                            className="yellow-badge">On-Order</span>
                                                                    </div>
                                                                    <div className="img-content-wrap">
                                                                        <div data-test-id="img-container" className="img-wrap">
                                                                            {item.attributes.catalogue.attributes.product_images?.length && (
                                                                                <img src={`${baseURL}${item.attributes.catalogue.attributes.product_images[0]?.url}`} alt="Card img" />
                                                                            )}
                                                                        </div>
                                                                            
                                                                        
                                                                        <div className="content-wrap">
                                                                            <span className="title">{item.attributes.catalogue.attributes?.title}</span>
                                                                            <div className="detail">
                                                                                <span className="order-description">{item.attributes.catalogue.attributes?.description}</span>
                                                                                <span>
                                                                                    <strong>
                                                                                        Size: </strong>
                                                                                    {item?.attributes?.catalogue_variant?.attributes?.catalogue_variant_size?.name}
                                                                                </span>
                                                                                <span><strong>Color: </strong>{item?.attributes?.catalogue_variant?.attributes?.catalogue_variant_color?.name}</span>
                                                                            </div>
                                                                            <div className="price-btn-wrap">
                                                                                <span
                                                                                    className="price">₹ {item?.attributes?.unit_price}
                                                                                </span>
                                                                                {
                                                                                    <Button className="custom-button primary-yellow small font-black">
                                                                                        Buy again
                                                                                    </Button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                :
                                                <Box style={{ width: '100%', display: 'flex', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img alt="no data" src={noDataFound} className={classes.noDataImg} />
                                                </Box>
                                            }
                                        </div>
                                    </div>
                                )}
                                {this.state.tabValue === 1 && (
                                    <div className="tabs-content">
                                        <div className="inner-tab-content">
                                            <div className="inner-tab-wrap">
                                                <div className="bargain-card-wrap">
                                                    <div className="bargain-card">
                                                        <div className="inner-wrap">
                                                            <div className="person-detail">
                                                                <div className="content-wrap">
                                                                    <div className="img-wrap">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Ivan Morais</span>
                                                                        <span className="detail">
                                                                            Morning coffee XS - 4X...
                                                                        </span>
                                                                    </div>
                                                                    <span className="blue-badge">
                                                                        <i>
                                                                            <img src={star} alt="Review" />
                                                                        </i>
                                                                        5.0
                                                                    </span>
                                                                </div>
                                                                <span className="yellow-badge">Bargain</span>
                                                            </div>
                                                            <div className="img-content-wrap">
                                                                <div className="img-wrap">
                                                                    <img src={bargainCardImg} alt="Card img" />
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">
                                                                        Limited edition sneakers
                                                                    </span>
                                                                    <div className="detail">
                                                                        <span>Nike limited edition sneakers</span>
                                                                        <span className="price">
                                                                            Base price:<strong>₹ 180</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="status-wrap">
                                                                <div className="content-wrap">
                                                                    <span className="title">Your offer:</span>
                                                                    <span className="detail">₹ 200</span>
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">Status:</span>
                                                                    <span className="detail">Pending</span>
                                                                </div>
                                                            </div>
                                                            <div className="btn-wrap">
                                                                <Button className="custom-button primary-yellow" 
                                                                    data-test-id="modifyOffer-1"
                                                                    onClick={() => this.handleOpenModalOffer()}>
                                                                    Modify offer
                                                                </Button>
                                                                <Button className="custom-button outline-yellow">
                                                                    Visit store
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bargain-card">
                                                        <div className="inner-wrap">
                                                            <div className="person-detail">
                                                                <div className="content-wrap">
                                                                    <div className="img-wrap">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Ivan Morais</span>
                                                                        <span className="detail">
                                                                            Morning coffee XS - 4X...
                                                                        </span>
                                                                    </div>
                                                                    <span className="blue-badge">
                                                                        <i>
                                                                            <img src={star} alt="Review" />
                                                                        </i>
                                                                        5.0
                                                                    </span>
                                                                </div>
                                                                <span className="yellow-badge">Bargain</span>
                                                            </div>
                                                            <div className="img-content-wrap">
                                                                <div className="img-wrap">
                                                                    <img src={bargainCardImg} alt="Card img" />
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">
                                                                        Limited edition sneakers
                                                                    </span>
                                                                    <div className="detail">
                                                                        <span>Nike limited edition sneakers</span>
                                                                        <span className="price">
                                                                            Base price:<strong>₹ 180</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="status-wrap">
                                                                <div className="content-wrap">
                                                                    <span className="title">Your offer:</span>
                                                                    <span className="detail">₹ 200</span>
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">Status:</span>
                                                                    <span className="detail red-text">
                                                                        Rejected
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="btn-wrap">
                                                                <Button className="custom-button primary-yellow" data-test-id="modifyOffer-2"
                                                                onClick={() => this.handleOpenModalOffer()}>
                                                                    Modify offer
                                                                </Button>
                                                                <Button className="custom-button outline-yellow">
                                                                    Visit store
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bargain-card">
                                                        <div className="inner-wrap">
                                                            <div className="person-detail">
                                                                <div className="content-wrap">
                                                                    <div className="img-wrap">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Ivan Morais</span>
                                                                        <span className="detail">
                                                                            Morning coffee XS - 4X...
                                                                        </span>
                                                                    </div>
                                                                    <span className="blue-badge">
                                                                        <i>
                                                                            <img src={star} alt="Review" />
                                                                        </i>
                                                                        5.0
                                                                    </span>
                                                                </div>
                                                                <span className="yellow-badge">Bargain</span>
                                                            </div>
                                                            <div className="img-content-wrap">
                                                                <div className="img-wrap">
                                                                    <img src={bargainCardImg} alt="Card img" />
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">
                                                                        Limited edition sneakers
                                                                    </span>
                                                                    <div className="detail">
                                                                        <span>Nike limited edition sneakers</span>
                                                                        <span className="price">
                                                                            Base price:<strong>₹ 180</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="status-wrap">
                                                                <div className="content-wrap">
                                                                    <span className="title">Your offer:</span>
                                                                    <span className="detail">₹ 200</span>
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">Status:</span>
                                                                    <span className="detail green-text">
                                                                        Accepted
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="product-msg-wrap">
                                                                <span>You have 7 days to buy this product</span>
                                                            </div>
                                                            <div className="btn-wrap">
                                                                <Button className="custom-button primary-yellow" data-test-id="modifyOffer-3"
                                                                onClick={() => this.handleOpenModalOffer()}>
                                                                    Modify offer
                                                                </Button>
                                                                <Button className="custom-button outline-yellow">
                                                                    Visit store
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bargain-card">
                                                        <div className="inner-wrap">
                                                            <div className="person-detail">
                                                                <div className="content-wrap">
                                                                    <div className="img-wrap">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Ivan Morais</span>
                                                                        <span className="detail">
                                                                            Morning coffee XS - 4X...
                                                                        </span>
                                                                    </div>
                                                                    <span className="blue-badge">
                                                                        <i>
                                                                            <img src={star} alt="Review" />
                                                                        </i>
                                                                        5.0
                                                                    </span>
                                                                </div>
                                                                <span className="yellow-badge">Bargain</span>
                                                            </div>
                                                            <div className="img-content-wrap">
                                                                <div className="img-wrap">
                                                                    <img src={bargainCardImg} alt="Card img" />
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">
                                                                        Limited edition sneakers
                                                                    </span>
                                                                    <div className="detail">
                                                                        <span>Nike limited edition sneakers</span>
                                                                        <span className="price">
                                                                            Base price:<strong>₹ 180</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-wrap">
                                                                <span className="gray-badge">Product Sold</span>
                                                                <Button className="custom-button outline-yellow">
                                                                    Visit store
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.tabValue === 2 && (
                                    <div className="tabs-content">
                                        <div className="inner-tab-content">
                                            <div className="inner-tab-wrap">
                                                <div className="bargain-card-wrap">
                                                    <div className="bargain-card">
                                                        <div className="inner-wrap">
                                                            <div className="person-detail">
                                                                <div className="content-wrap">
                                                                    <div className="img-wrap">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Ivan Morais</span>
                                                                        <span className="detail">
                                                                            Morning coffee XS - 4X...
                                                                        </span>
                                                                    </div>
                                                                    <span className="blue-badge">
                                                                        <i>
                                                                            <img src={star} alt="Review" />
                                                                        </i>
                                                                        5.0
                                                                    </span>
                                                                </div>
                                                                <span className="yellow-badge">On-Order</span>
                                                            </div>
                                                            <div className="img-content-wrap">
                                                                <div className="img-wrap">
                                                                    <img src={bargainCardImg} alt="Card img" />
                                                                </div>
                                                                <div className="content-wrap">
                                                                    <span className="title">
                                                                        Limited edition sneakers
                                                                    </span>
                                                                    <div className="detail">
                                                                        <span>Nike limited edition sneakers</span>
                                                                        <span className="price">
                                                                            Base price:<strong>₹ 180</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-wrap">
                                                                <Button className="custom-button primary-yellow">
                                                                    Send Request
                                                                </Button>
                                                                <Button className="custom-button outline-yellow">
                                                                    Visit store
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.tabValue === 3 && (
                                    <div className="tabs-content">
                                        <div className="inner-tab-content">
                                            <div className="inner-tab-wrap">
                                                <div className="post-cards-wrap">
                                                    <div className="post-card">
                                                        <div className="inner-wrap">
                                                            <div className="img-wrap">
                                                                <div className="inner-img-wrap">
                                                                    <img src={postCardImg} alt="Card img" />
                                                                </div>
                                                                <IconButton className="archive-icon-btn">
                                                                    <img src={archiveIc} alt="archive" />
                                                                </IconButton>
                                                                <div className="detail-wrap">
                                                                    <div className="img">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Devon Miles</span>
                                                                        <span className="detail">
                                                                            Verified Buyer
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-wrap">
                                                                <div className="content">
                                                                    <span>
                                                                        "I went to Indonesia , had a lot of fun with
                                                                        this cute doggie“.{" "}
                                                                    </span>
                                                                    <Button
                                                                        className="trasparent-button"
                                                                        disableRipple
                                                                    >
                                                                        Read more..
                                                                    </Button>
                                                                </div>
                                                                <div className="btn-wrap">
                                                                    <div className="like-wrap">
                                                                        <IconButton className="icon-btn">
                                                                            <img src={likeIc} alt="Like" />
                                                                        </IconButton>
                                                                        <span className="count">25</span>
                                                                    </div>
                                                                    <span className="divider">|</span>
                                                                    <IconButton className="icon-btn">
                                                                        <img src={shareIc} alt="Share" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-card">
                                                        <div className="inner-wrap">
                                                            <div className="img-wrap">
                                                                <div className="inner-img-wrap">
                                                                    <img src={postCardImg} alt="Card img" />
                                                                </div>
                                                                <IconButton className="archive-icon-btn">
                                                                    <img src={archiveIc} alt="archive" />
                                                                </IconButton>
                                                                <div className="detail-wrap">
                                                                    <div className="img">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Devon Miles</span>
                                                                        <span className="detail">
                                                                            Verified Buyer
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-wrap">
                                                                <div className="content">
                                                                    <span>
                                                                        "I went to Indonesia , had a lot of fun with
                                                                        this cute doggie“.{" "}
                                                                    </span>
                                                                    <Button
                                                                        className="trasparent-button"
                                                                        disableRipple
                                                                    >
                                                                        Read more..
                                                                    </Button>
                                                                </div>
                                                                <div className="btn-wrap">
                                                                    <div className="like-wrap">
                                                                        <IconButton className="icon-btn">
                                                                            <img src={likeIc} alt="Like" />
                                                                        </IconButton>
                                                                        <span className="count">25</span>
                                                                    </div>
                                                                    <span className="divider">|</span>
                                                                    <IconButton className="icon-btn">
                                                                        <img src={shareIc} alt="Share" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-card">
                                                        <div className="inner-wrap">
                                                            <div className="img-wrap">
                                                                <div className="inner-img-wrap">
                                                                    <img src={postCardImg} alt="Card img" />
                                                                </div>
                                                                <IconButton className="archive-icon-btn">
                                                                    <img src={archiveIc} alt="archive" />
                                                                </IconButton>
                                                                <div className="detail-wrap">
                                                                    <div className="img">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Devon Miles</span>
                                                                        <span className="detail">
                                                                            Verified Buyer
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-wrap">
                                                                <div className="content">
                                                                    <span>
                                                                        "I went to Indonesia , had a lot of fun with
                                                                        this cute doggie“.{" "}
                                                                    </span>
                                                                    <Button
                                                                        className="trasparent-button"
                                                                        disableRipple
                                                                    >
                                                                        Read more..
                                                                    </Button>
                                                                </div>
                                                                <div className="btn-wrap">
                                                                    <div className="like-wrap">
                                                                        <IconButton className="icon-btn">
                                                                            <img src={likeIc} alt="Like" />
                                                                        </IconButton>
                                                                        <span className="count">25</span>
                                                                    </div>
                                                                    <span className="divider">|</span>
                                                                    <IconButton className="icon-btn">
                                                                        <img src={shareIc} alt="Share" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-card">
                                                        <div className="inner-wrap">
                                                            <div className="img-wrap">
                                                                <div className="inner-img-wrap">
                                                                    <img src={postCardImg} alt="Card img" />
                                                                </div>
                                                                <IconButton className="archive-icon-btn">
                                                                    <img src={archiveIc} alt="archive" />
                                                                </IconButton>
                                                                <div className="detail-wrap">
                                                                    <div className="img">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Devon Miles</span>
                                                                        <span className="detail">
                                                                            Verified Buyer
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-wrap">
                                                                <div className="content">
                                                                    <span>
                                                                        "I went to Indonesia , had a lot of fun with
                                                                        this cute doggie“.{" "}
                                                                    </span>
                                                                    <Button
                                                                        className="trasparent-button"
                                                                        disableRipple
                                                                    >
                                                                        Read more..
                                                                    </Button>
                                                                </div>
                                                                <div className="btn-wrap">
                                                                    <div className="like-wrap">
                                                                        <IconButton className="icon-btn">
                                                                            <img src={likeIc} alt="Like" />
                                                                        </IconButton>
                                                                        <span className="count">25</span>
                                                                    </div>
                                                                    <span className="divider">|</span>
                                                                    <IconButton className="icon-btn">
                                                                        <img src={shareIc} alt="Share" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-card">
                                                        <div className="inner-wrap">
                                                            <div className="img-wrap">
                                                                <div className="inner-img-wrap">
                                                                    <img src={postCardImg} alt="Card img" />
                                                                </div>
                                                                <IconButton className="archive-icon-btn">
                                                                    <img src={archiveIc} alt="archive" />
                                                                </IconButton>
                                                                <div className="detail-wrap">
                                                                    <div className="img">
                                                                        <img src={profileImg} alt="Person" />
                                                                    </div>
                                                                    <div className="content">
                                                                        <span className="title">Devon Miles</span>
                                                                        <span className="detail">
                                                                            Verified Buyer
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="content-wrap">
                                                                <div className="content">
                                                                    <span>
                                                                        "I went to Indonesia , had a lot of fun with
                                                                        this cute doggie“.{" "}
                                                                    </span>
                                                                    <Button
                                                                        className="trasparent-button"
                                                                        disableRipple
                                                                    >
                                                                        Read more..
                                                                    </Button>
                                                                </div>
                                                                <div className="btn-wrap">
                                                                    <div className="like-wrap">
                                                                        <IconButton className="icon-btn">
                                                                            <img src={likeIc} alt="Like" />
                                                                        </IconButton>
                                                                        <span className="count">25</span>
                                                                    </div>
                                                                    <span className="divider">|</span>
                                                                    <IconButton className="icon-btn">
                                                                        <img src={shareIc} alt="Share" />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="you-may-also-like-section">
                    <div className="container">
                        <div className="title-wrap">
                            <span className="title">You May Also Like</span>
                            <Link className="see-all-link" to={'BuyermarketPlace'}>
                                See all
                                <i>
                                    <img src={rightArrow} alt="Arrow" />
                                </i>
                            </Link>
                        </div>
                    </div>
                    <div className="slider-wrap">
                        <Grid className={classes.suggestionContainer}>
                            {this.slidder()}
                        </Grid>
                    </div>
                </section>
                <Dialog
                    open={this.state.openModalOffer}
                    data-test-id="offerClose"
                    onClose={() => this.handleCloseModalOffer()}
                    className="offer-dialog"
                    scroll="body"
                >
                    <DialogTitle className="title-wrap">
                        <span>Make offer</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="offerCloseButton"
                            onClick={() => this.handleCloseModalOffer()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="main">
                                <div>
                                    <span className="price">
                                        Base price:<strong>₹ 180</strong>
                                    </span>
                                </div>
                                <div className="search-wrap">
                                    <TextField
                                        variant="outlined"
                                        placeholder="₹ 0"
                                        className="search-input"
                                    />
                                </div>
                                <div className="discount">
                                    <Button
                                        className="discount-button"
                                    >
                                        5% Less
                                    </Button>
                                    <Button
                                        className="discount-button"
                                    >
                                        15% Less
                                    </Button>
                                    <Button
                                        className="discount-button"
                                    >
                                        25% Less
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="offerCloseModalButton"
                            onClick={() => this.handleCloseModalOffer()}
                            className="custom-button primary-yellow make-offer"
                        >
                            Make offer
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openModalFollowing}
                    data-test-id="followingClose"
                    onClose={() => this.handleCloseModalFollowing()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Following</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="followingCloseButton"
                            onClick={() => this.handleCloseModalFollowing()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    
                    <div className="search-wrap">
                        <TextFieldChange
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={searchIcon} alt="Search" />
                                    </InputAdornment>
                                ),
                            }}
                            data-test-id="followingSearchFilter"
                            onChange={this.handleFolloersListSearch}
                            variant="outlined"
                            placeholder="Search"
                            className="search-input"
                            // style={{ width : '100%'}}
                        />
                    </div>

                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            {
                                this.state.sallerFollowingModalData.data.map((item, index) =>
                                (
                                    <div key={index} className="person-detail-wrap">
                                        <div className="img-wrap">
                                            <img src={profileImg} alt="Profile" />
                                        </div>
                                        <div className="detail">
                                            <span className="title">{item.attributes.account.user_name}</span>
                                            <span className="name">{item.attributes.account.full_name}</span>
                                        </div>
                                        <div className="unfollow-btn">
                                            {
                                                "follow" == item.type ?
                                                    <Button data-test-id='unfollow_button' onClick={() => this.unFollowSellerApiCall(item.attributes.account.id,item.id)} className="custom-button outline-yellow font-gray">
                                                        Following
                                                    </Button> :
                                                    <Button data-test-id='unfollow_button' onClick={() => this.clickFollowButton(item.attributes.followable_id,item.id)} className="custom-button outline-yellow font-gray">
                                                        follow
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalFollowers}
                    data-test-id="followersClose"
                    onClose={() => this.handleCloseModalFollowers()}
                    className="custom-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Followers</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="followersCloseButton"
                            onClick={() => this.handleCloseModalFollowers()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 1</span>
                                    <span className="name">Vaibhav Verma 1</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 2</span>
                                    <span className="name">Vaibhav Verma 2</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 3</span>
                                    <span className="name">Vaibhav Verma 3</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 4</span>
                                    <span className="name">Vaibhav Verma 4</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 5</span>
                                    <span className="name">Vaibhav Verma 5</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 6</span>
                                    <span className="name">Vaibhav Verma 6</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 7</span>
                                    <span className="name">Vaibhav Verma 7</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 8</span>
                                    <span className="name">Vaibhav Verma 8</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 9</span>
                                    <span className="name">Vaibhav Verma 9</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 10</span>
                                    <span className="name">Vaibhav Verma 10</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 11</span>
                                    <span className="name">Vaibhav Verma 11</span>
                                </div>                                
                            </div>
                            <div className="person-detail-wrap">
                                <div className="img-wrap">
                                    <img src={profileImg} alt="Profile" />
                                </div>
                                <div className="detail">
                                    <span className="title">Breakout_Swinger 12</span>
                                    <span className="name">Vaibhav Verma 12</span>
                                </div>                                
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openModalFilter}
                    data-test-id="filterClose"
                    onClose={() => this.handleCloseModalFilter()}
                    className="filter-dialog"
                >
                    <DialogTitle className="title-wrap">
                        <span>Filter</span>
                        <IconButton
                            aria-label="close"
                            className="close-btn"
                            data-test-id="filterCloseButton"
                            onClick={() => this.handleCloseModalFilter()}
                        >
                            <img src={CloseIc} alt="Close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="filter-order-category">
                            <div className="filter-title">
                                <span>By Order Type</span>
                            </div>
                            <div className="radio-container">
                                <label className="radio-position">
                                    <span>All Orders</span>
                                    <input type="radio" checked={this.state.orderType === ""} onChange={this.handleOrderFilterByChange} data-test-id="allOrder" name="orderType" value="" />
                                </label>
                                <label className="radio-position">
                                    <span>In Progress</span>
                                    <input type="radio" checked={this.state.orderType === "in_progress"} onChange={this.handleOrderFilterByChange} data-test-id="inprogressOrder" name="orderType" value="in_progress" />
                                </label>
                                <label className="radio-position">
                                    <span>Cancelled</span>
                                    <input type="radio" checked={this.state.orderType === 'declined'} onChange={this.handleOrderFilterByChange} data-test-id="cancelledOrder" name="orderType" value="declined" />
                                </label>
                            </div>
                        </div>
                        <div className="filter-date-category">
                            <div className="filter-title">
                                <span>By Date</span>
                            </div>
                            <div className="radio-container">
                                <label className="radio-position">
                                    <span>Last 30 Days</span>
                                    <input type="radio" checked={this.state.dateType === '30day'} onChange={this.handleDateFilterByChange} data-test-id="date30" name="dateType" value="30day" />
                                </label>
                                <label className="radio-position">
                                    <span>Last 3 Months</span>
                                    <input type="radio" checked={this.state.dateType === '3month'} onChange={this.handleDateFilterByChange} data-test-id="month3" name="dateType" value="3month" />
                                </label>
                                <label className="radio-position">
                                    <span>Last Year</span>
                                    <input type="radio" checked={this.state.dateType === '1year'} onChange={this.handleDateFilterByChange} data-test-id="year1" name="dateType" value="1year" />
                                </label>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap-filter">
                        <Button
                            data-test-id="filterCloseModalButtonClear"
                            onClick={(e) => this.orderSearchSubmit(e)}
                            className="custom-button primary-yellow buyer-profile-filter-yellow"
                        >
                            Show results
                        </Button>
                        <Button
                            data-test-id="clearCloseModalButtonClear"
                            onClick={() => this.handleClearFilter()}
                            className="custom-button outline-yellow"
                        >
                            Clear
                        </Button>
                    </DialogActions>
                </Dialog>
                <MessageModalWeb
                    displayPopup={this.state.messageModel}
                    closeErrorPopup={this.closeMessageModal}
                    errorMessage={this.state.message} />
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const TextFieldChange = styled(TextField)({
    width: '100%',
        '& .MuiOutlinedInput-root': {
            // '& fieldset': {
            //     borderColor: 'yellow', // Default border color
            // },
            '&:hover fieldset': {
                borderColor: 'yellow', // Border color on hover
            },
            '&.Mui-focused fieldset': {
                borderColor: 'yellow', // Border color when focused
            },
        },
})
const styles: StyleRules = {
    suggestionContainer: {
        '& .slick-slide': {
            height: "auto",
            maxWidth: '340px',
            width: '100%'
        },
        '& .slick-track': {
            display: 'flex !important',
            gap: '16px',
            alignItems: 'center',
            height: "384px",
        },
        '& .slick-next': {
            display: 'none !important'
        },
        '& .slick-prev': {
            display: 'none !important'
        }
    },
    suggestionBox: {
        maxWidth: '340px',
        position: "relative",
        display: 'flex !important',
        flexDirection: 'column',
        gap: '16px',
    },
    archiveBox: {
        position: "absolute",
        right: "24px",
        top: "24px",
        height: '31px',
        width: '32px',
        borderRadius: "31px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: "#00000099",
        cursor: 'pointer'
    },
    profileBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '24px'
    },
    profilePicture: {
        display: "flex",
        alignItems: "center",
        gap: '8px'
    },
    profileTxt: {
        WebkitLineClamp: 1,
        overflow: 'hidden',
        display: '-webkit-box',
        whiteSpace: 'normal',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        fontSize: '14px'
    },
    noDataImg: {
        width: '100%',
        maxWidth: '300px',
        animation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        WebkitAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
    },
}
// @ts-ignore
export default  withStyles(styles)(withRouter(UserProfiles))
export {UserProfiles}
// Customizable Area End
// Customizable Area End