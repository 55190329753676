import React from "react";
// Customizable Area Start
import Cfflixoolivewallet0Controller, {
  Props,
} from "./Cfflixoolivewallet0Controller";
import { Box, styled, TextField, Typography, Button } from "@material-ui/core";
import { arrowLeftIc } from "../../CustomisableUserProfiles/src/assets";
// Customizable Area End

export default class Cfflixoolivewallet0 extends Cfflixoolivewallet0Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {isTransferOpen, isAddOpen, transferAmt, addAmount} = this.state;
    const transactionData = [
      { reason: "Paid for product", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for live streaming", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for product", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for live streaming", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for product", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for live streaming", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
  ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <WalletStyleWrapper >
        <Box className="mainContainer">
          {!isTransferOpen && !isAddOpen &&
            <span>
              <Box className="headingBox">
                <Typography className="headingTxt">Flixoo Wallet</Typography>
              </Box>

              <Box className="contentBodyBox">
                <Box className="walletInfoBox">
                  <Typography className="walletPoint">5099</Typography>
                  <Typography className="pointTitle">Flixoo Points</Typography>
                  <Typography className="pointInfoTxt">1 Flixoo Point = ₹1 on Flixoo App</Typography>
                  <Box className="buttonBox">
                    <Button className="transferBtn btnClass" data-test-id="transferBtnTestId" onClick={this.handleTransferToogle}>
                      Transfer from Wallet
                    </Button>

                    <Button className="addWalletBtn btnClass" data-test-id="addBtnTestId" onClick={this.handleAmountToogle}>
                      Add to Wallet
                    </Button>
                  </Box>

                  <Box className="historyBox">
                    <Box className="historyTitleBox">
                      <Typography className="titleHistory">Your Transaction</Typography>
                    </Box>
                    <Box className="transactionDetailBox">
                      {transactionData.map((item, itemIdx) => (
                        <Box className="transactionDetails" key={itemIdx}>
                          <Typography className="transactionReason">{item.reason}</Typography>
                          <Typography className={`transactionAmt ${item.isCredited ? "credited" : "debited"}`}>{item.isCredited ? "+" : "-"} {item.amount}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                </Box>
              </Box>
            </span>
          }

          {isTransferOpen &&
            <Box className="transferBox">
              <Box className="headerBox">
                <img src={arrowLeftIc} className="backIconImg" data-test-id="backTransferTestId" onClick={this.handleTransferToogle} />
                <Typography className="headingTxt">Transfer from Wallet</Typography>
              </Box>

              <Box className="moneyContainer">
                <Typography className="infoTitle">Amount to be Transferred: </Typography>
                <TextField value={transferAmt === 0 ? "" : transferAmt} data-test-id="inputTransferTestId" onChange={this.handleChangeTransferAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                <Box>
                  <Button className="saveButton">Transfer</Button>
                </Box>
              </Box>
            </Box>
          }

          {isAddOpen &&
            <Box className="transferBox">
              <Box className="headerBox">
                <img src={arrowLeftIc} className="backIconImg" data-test-id="backAddrTestId" onClick={this.handleAmountToogle} />
                <Typography className="headingTxt">Add money to Wallet</Typography>
              </Box>

              <Box className="moneyContainer">
                <Typography className="infoTitle">Amount to be Added: </Typography>
                <TextField value={addAmount === 0 ? "" : addAmount} data-test-id="inputAddTestId" onChange={this.handleChangeAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                <Box> <Button className="saveButton">Proceed to Payment</Button></Box>
              </Box>
            </Box>
          }
        </Box>
      </WalletStyleWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WalletStyleWrapper = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  marginTop: "135px",
  padding: "0px 24px",
  "& .headingTxt": {
      fontSize: "20px",
      fontWeight: 500,
      color: "#444444CC"
  },
  "& .walletInfoBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "60px",
  },
  "& .walletPoint": {
      fontSize: "60px",
      fontWeight: 700,
      color: "#444444"
  },
  "& .pointTitle": {
      fontSize: "14px",
      fontWeight: 500,
      color: "#444444"
  },
  "& .pointInfoTxt": {
      fontSize: "12px",
      fontWeight: 500,
      color: "#44444466",
      marginTop: "12px"
  },
  "& .buttonBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      marginTop: "32px",
  },
  "& .btnClass": {
      backgroundColor: "#F5EA16",
      padding: "12px 8px",
      width: "100%",
      minWidth: "163px",
      maxWidth: "163px",
      textTransform: "none",
      fontSize: "12px",
      fontWeight: 600,
      color: "#444444",
      borderRadius: "8px"
  },
  "& .historyBox": {
      width: "100%",
      maxWidth: "580px",
      marginTop: "48px"
  },
  "& .historyTitleBox": {
      borderBottom: "1px solid #4444441A",
      paddingBottom: "10px"
  },
  "& .transactionDetailBox": {
      height: "100%",
      maxHeight: "500px",
      overflowY: "scroll",
      scrollbarWidth: "thin"
  },
  "& .transactionDetails": {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #4444441A",
      padding: "20px 0px"
  },
  "& .credited": {
      color: "#0DAD01"
  },
  "& .debited": {
      color: "#ED2224"
  },
  "& .transactionAmt": {
      fontSize: "14px",
      fontWeight: 400,
      marginRight: "4px"
  },
  "& .transactionReason": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#444444"
  },
  "& .titleHistory": {
      fontSize: "16px",
      fontWeight: 600,
      color: "#444444",
  },
  "& .headerBox": {
      display: "flex",
      alignItems: "center",
      gap: "8px"
  },
  "& .backIconImg": {
      width: "25px",
      cursor: "pointer"
  },
  "& .moneyContainer":{
      marginTop:"72px"
  },
  "& .infoTitle":{
      fontSize:"16px",
      fontWeight: 500,
      color: "#000000"
  },
  "& .amountInput": {
      marginTop: "40px",
      border: "1px solid #44444433",
      borderRadius: "8px",
      background: "white",
      padding: "12px 16px",
      width: "100%",
      maxWidth: "130px",
      "& ::placeholder": {
          fontSize: "12px",
          fontWeight: 400,
          color: "#44444480"
      }
  },
  "& .saveButton":{
      textTransform:"none",
      fontSize:"12px",
      fontWeight: 600,
      color:"#444444",
      background:"#F5EA16",
      padding:"12px 16px",
      width:"100%",
      maxWidth:"342px",
      borderRadius:"8px",
      marginTop:"40px"
  },
});
// Customizable Area End
