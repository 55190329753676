import React from "react";
// Customizable Area Start
import SellerWalletController, {
  Props,
} from "./SellerWalletController";
import { Box, styled, TextField, Typography, Button, IconButton } from "@material-ui/core";
import { arrowLeftIc, hamburgerIc } from "../../CustomisableUserProfiles/src/assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
// Customizable Area End

export default class SellerWallet extends SellerWalletController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { transferOpen, addProductOpen, tranferAmount, addPrice } = this.state;
    const transactionMockData = [
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for product", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for live streaming", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for product", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for live streaming", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for product", amount: 400, isCredited: false },
      { reason: "Refund recieved", amount: 1000, isCredited: true },
      { reason: "Paid for live streaming", amount: 400, isCredited: false },
    ];

    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="main-div">
        <div className="dashboard-outer">
          <div className="dashboard-inner">
            <SellerSidebar
              navigation={"/SellerFlixooWallet"}
              id={""}
              classes={undefined}
              isOpen={this.state.isDrawer}
              data-test-id="sellerSidebar"
              activetabName="payments"
              closeModal={() => this.toogleDrawer()}
            />
            <SellerWalletWrapper >
              <Box className="mainWrapper">
                {!transferOpen && !addProductOpen &&
                  <span>
                    <Box className="headingContainer"> <Typography className="headingText">Flixoo Wallet</Typography> <IconButton onClick={()=> this.toogleDrawer()} className="drawerIcon" ><img src={hamburgerIc} alt=""/></IconButton></Box>

                    <Box className="contentBox">
                      <Box className="walletInfoContainer">
                        <Typography className="flixooPointTxt">5099</Typography>
                        <Typography className="pointTxt">Flixoo Points</Typography>
                        <Typography className="pointInformation">1 Flixoo Point = ₹1 on Flixoo App</Typography>
                        <Box className="buttonContainer">
                          <Button className="transferBtn buttonClass" data-test-id="transferBtnTestId" onClick={this.handleTransferToogle}> Transfer from Wallet </Button>

                          <Button className="addWalletBtn buttonClass" data-test-id="addBtnTestId" onClick={this.handleAmountToogle}> Add to Wallet </Button>
                        </Box>

                        <Box className="historyContainer">
                          <Box className="historyInnerContainer">
                            <Typography className="historyTitle">Your Transaction</Typography>
                          </Box>
                          <Box className="transactionContainer">
                            {transactionMockData.map((item, itemIdx) => (
                              <Box className="detailBox" key={itemIdx}>
                                <Typography className="reasonText">{item.reason}</Typography>
                                <Typography className={`amountText ${item.isCredited ? "credited" : "debited"}`}>{item.isCredited ? "+" : "-"} {item.amount}</Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </span>
                }

                {transferOpen &&
                  <Box className="transferScreen">
                    <Box className="innerHeaderBox">
                      <img src={arrowLeftIc} className="backIcon" data-test-id="backTransferTestId" onClick={this.handleTransferToogle} />
                      <Typography className="headingText">Transfer from Wallet</Typography>
                    </Box>

                    <Box className="subInnerContainer">
                      <Typography className="subInnerHeading">Amount to be Transferred: </Typography>
                      <TextField value={tranferAmount === 0 ? "" : tranferAmount} data-test-id="inputTransferTestId" onChange={this.handleChangeTransferAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                      <Box>
                        <Button className="saveButtonStyle">Transfer</Button>
                      </Box>
                    </Box>
                  </Box>
                }

                {addProductOpen &&
                  <Box className="transferScreen">
                    <Box className="innerHeaderBox">
                      <img src={arrowLeftIc} className="backIcon" data-test-id="backAddrTestId" onClick={this.handleAmountToogle} />
                      <Typography className="headingText">Add money to Wallet</Typography>
                    </Box>

                    <Box className="subInnerContainer">
                      <Typography className="subInnerHeading">Amount to be Added: </Typography>
                      <TextField value={addPrice === 0 ? "" : addPrice} data-test-id="inputAddTestId" onChange={this.handleChangeAmount} type="text" className="amountInput" placeholder="₹ Enter Amount" InputProps={{ disableUnderline: true }} />
                      <Box> <Button className="saveButtonStyle">Proceed to Payment</Button></Box>
                    </Box>
                  </Box>
                }
              </Box>
            </SellerWalletWrapper>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SellerWalletWrapper = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  marginTop: "25px",
  padding: "0px 24px",
  "& .headingText": {
    fontSize: "20px",
    fontWeight: 500,
    color: "#444444CC"
  },
  "& .walletInfoContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "60px",
  },
  "& .flixooPointTxt": {
    fontSize: "60px",
    fontWeight: 700,
    color: "#444444"
  },
  "& .pointTxt": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#444444"
  },
  "& .pointInformation": {
    fontSize: "12px",
    fontWeight: 500,
    color: "#44444466",
    marginTop: "12px"
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: "32px",
  },
  "& .buttonClass": {
    backgroundColor: "#F5EA16",
    padding: "12px 8px",
    width: "100%",
    minWidth: "163px",
    maxWidth: "163px",
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#444444",
    borderRadius: "8px"
  },
  "& .historyContainer": {
    width: "100%",
    maxWidth: "580px",
    marginTop: "48px"
  },
  "& .historyInnerContainer": {
    borderBottom: "1px solid #4444441A",
    paddingBottom: "10px"
  },
  "& .transactionContainer": {
    height: "100%",
    maxHeight: "500px",
    overflowY: "scroll",
    scrollbarWidth: "thin"
  },
  "& .detailBox": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #4444441A",
    padding: "20px 0px"
  },
  "& .credited": {
    color: "#0DAD01"
  },
  "& .debited": {
    color: "#ED2224"
  },
  "& .amountText": {
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "4px"
  },
  "& .reasonText": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#444444"
  },
  "& .historyTitle": {
    fontSize: "16px",
    fontWeight: 600,
    color: "#444444",
  },
  "& .innerHeaderBox": {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  "& .backIcon": {
    width: "25px",
    cursor: "pointer"
  },
  "& .subInnerContainer": {
    marginTop: "72px"
  },
  "& .subInnerHeading": {
    fontSize: "16px",
    fontWeight: 500,
    color: "#000000"
  },
  "& .amountInput": {
    marginTop: "40px",
    border: "1px solid #44444433",
    borderRadius: "8px",
    background: "white",
    padding: "12px 16px",
    width: "100%",
    maxWidth: "130px",
    "& ::placeholder": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#44444480"
    }
  },
  "& .saveButtonStyle": {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#444444",
    background: "#F5EA16",
    padding: "12px 16px",
    width: "100%",
    maxWidth: "342px",
    borderRadius: "8px",
    marginTop: "40px"
  },
  "& .headingContainer":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  "& .drawerIcon":{
    display:"none",
    "@media(max-width:991px)":{
      display:"block"
    }
  }
});
// Customizable Area End
