import React from "react";
// Customizable Area Start
import {
    Button,
    IconButton,
    Box,
    Avatar,
    styled,
    Tabs,
    Tab,
    Dialog,
    Typography,
    DialogContent,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    AddYellowIc,
    EditYellowIc,
    bannerImageBlankSeller,
    group,
    youtubeIcon,
    instagramIcon,
    FlixooIcon,
    group_link,
    AddGrayIc
} from "./assets";
import StayTuned from "../../../components/src/StayTuned.web";
import CreatorProfileController, { CreatorResponseData, PostData, Props } from "./CreatorProfileController";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import ReactPlayer from 'react-player';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class CreatorProfile extends CreatorProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractSellerData = () => {
        let businessName = ""
        let username = ""
        let profilephoto = ""
        let backgroundphoto = ""
        let bio = ""
        let instagram_followers = 0
        let youtube_followers = 0
        let followers = 0
        let instagram_url = ""
        if (this.state.creatorProfileData) {
            businessName = truthyValue(this.state.creatorProfileData.attributes.full_name)
            username = truthyValue(this.state.creatorProfileData.attributes.user_name)
            profilephoto = truthyValue(this.state.creatorProfileData.attributes.profile_photo)
            backgroundphoto = truthyValue(this.state.creatorProfileData.attributes.background_photo)
            bio = truthyValue(this.state.creatorProfileData.attributes.my_bio)
            instagram_followers = this.state.creatorProfileData?.attributes.instagram_followers
            youtube_followers = this.state.creatorProfileData?.attributes.youtube_followers
            followers = this.state.creatorProfileData?.attributes.followers
            instagram_url = truthyValue(this.state.creatorProfileData?.attributes.instagram_url)
        }
        return { businessName, username, profilephoto, backgroundphoto, bio, instagram_followers, youtube_followers, followers, instagram_url }
    };


    renderPosts = () => {
        return (
            this.state.tabValue === 0 && (
                <div className="seller-tabs-content">
                    <div className="seller-inner-tab-content">
                        <div className="seller-inner-tab-wrap">
                            <div className="post-cards-wrapp">
                                <Box className="postInnerContainer1">
                                    {this.state.creatorProfileData?.attributes?.posts.data?.map(
                                        (postData: PostData, postIndex: number) => {
                                            return (
                                                <PostCardBox>
                                                    <Box className="postImageBox">
                                                        {postData?.attributes?.images_and_videos?.length === 1 ? (
                                                            (() => {
                                                                const media = postData.attributes.images_and_videos[0];
                                                                if (media.type === 'video') {
                                                                    return (
                                                                        <ReactPlayer
                                                                            style={{ height: "unset !important" }}
                                                                            controls
                                                                            className="postImage1"
                                                                            url={media.url}
                                                                        />
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <img
                                                                            src={media.url}
                                                                            alt="Post"
                                                                            className="postImage"
                                                                            onClick={() => this.handleOpenImage(media.url)}
                                                                        />
                                                                    );
                                                                }
                                                            })()
                                                        ) : (
                                                            <Carousel
                                                                showThumbs={false}
                                                                swipeable={true}
                                                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                                                    hasNext && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={onClickHandler}
                                                                            title={label}
                                                                            className="nextArrowBtn"
                                                                        >
                                                                            <ArrowForwardIos className="postSlideBtn" />
                                                                        </button>
                                                                    )
                                                                }
                                                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                                                    hasPrev && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={onClickHandler}
                                                                            title={label}
                                                                            className="prevArrowBtn"
                                                                        >
                                                                            <ArrowBackIos className="postSlideBtn" />
                                                                        </button>
                                                                    )
                                                                }
                                                            >
                                                                {postData?.attributes?.images_and_videos.map((media, index) => (
                                                                    <Box key={index} className="imageContainer">
                                                                        {media.type === 'video' ? (
                                                                            <ReactPlayer
                                                                                style={{ height: "unset !important" }}
                                                                                url={media.url}
                                                                                controls
                                                                                className="postImage1"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={media.url}
                                                                                alt={`Media ${index + 1}`}
                                                                                className="postImage"
                                                                                onClick={() => this.handleOpenImage(media.url)}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                ))}
                                                            </Carousel>
                                                        )}
                                                    </Box>
                                                    <Dialog
                                                        open={this.state.isPostOpen}
                                                        data-test-id="showPostTestId"
                                                        onClose={this.handleClosePostModal}
                                                    >
                                                        <DialogContent>
                                                            <img src={this.state.carousalUrl} width={"100%"} height={"100%"} alt="loading"/>
                                                        </DialogContent>
                                                    </Dialog>
                                                </PostCardBox>
                                            );
                                        }
                                    )}
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
    
    followerDetailsDialog = () => {
        const { followsTabValue, isFollowsModal } = this.state;
        return (
            <Dialog open={isFollowsModal} onClose={this.handleToggleModal} data-test-id="close-succcess-dialog" PaperProps={{ style: paperDialogStyle, }} className={"dialogBackground"} >
                <CustomDialogContent className={"dialog"}>
                    <Box className="innerContainer">
                        <Tabs value={followsTabValue} onChange={this.handleChangeTab} className="custom-tabs-list" data-test-id="creatorFollowsTab">
                            <Tab label="Followers" disableRipple className="tabName" />
                            <Tab label="Following" disableRipple className="tabName" />
                        </Tabs>
                    </Box>
                    {followsTabValue == 0 && this.renderFollowersList()}
                    {followsTabValue == 1 && this.renderFollowingList()}
                </CustomDialogContent>
            </Dialog>
        )
    }

    renderFollowersList = () => {
        const { followers } = this.state;
        return (
            <Box className="listBox">
                {followers.length > 0 ? followers.map((item, itemIdx) => (
                    <Box className="listingInnerContainer" key={itemIdx}>
                        <Box className="profileBox">
                            <Avatar className="userAvatar" src={item.attributes.account.profile_url} />
                        </Box>
                        <Box className="profileDetailsBox">
                            <Typography className="userNameTxt">{item.attributes.account.user_name}</Typography>
                            <Typography className="fullNameTxt">{item.attributes.account.full_name}</Typography>
                        </Box>
                    </Box>
                )) : <Box>No followers found</Box>}
            </Box>
        )
    };

    renderFollowingList = () => {
        const { following } = this.state;
        return (
            <Box className="listBox">
                {following.length > 0 ? following.map((item, itemIdx) => (
                    <Box className="listingInnerContainer" key={itemIdx}>
                        <Box className="profileBox">
                            <Avatar className="userAvatar" src={item.attributes.account.profile_url} />
                        </Box>
                        <Box className="profileDetailsBox">
                            <Typography className="userNameTxt">{item.attributes.account.user_name}</Typography>
                            <Typography className="fullNameTxt">{item.attributes.account.full_name}</Typography>
                        </Box>
                    </Box>
                )) : <Box>No following found</Box>}
            </Box>
        )
    };

    renderFollowUnfollowButton = () => {
        let {isFollowingUser} = this.state;
        return (
            this.state.creatorID &&
            <span>
                <Button className={`followButton ${isFollowingUser === true && "notFollowing"}`} onClick={()=> this.toggleFollowUnFollow()}>{isFollowingUser ? `Unfollow`: `Follow`}</Button>
            </span>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { businessName, username, profilephoto, backgroundphoto, bio, instagram_followers, youtube_followers, followers, instagram_url } = this.abstractSellerData();
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div">
                {!this.state.creatorID && this.followerDetailsDialog()}
                <MainStyleWrapper>
                <Loader loading={this.state.loading} />
                <div className="dashboard-outer" data-test-id="sellerProfileMainContainer">
                    <div className="right-outer">
                        <div className="right-inner">
                            <section className="banner-section-seller">
                                {backgroundphoto ?
                                    <div className="banner-img-wrap-seller">
                                        <img src={backgroundphoto} alt="banner Img" />
                                    </div>
                                    :
                                    <div className="banner-img-wrap-seller no-bg">
                                        <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                                    </div>
                                }
                                <div className="profile-detail-seller">
                                    <div className="profile-detail-inner-seller">
                                        <div className="profile-photo-wrap-seller">
                                            <Avatar src={profilephoto} className="profile-photo-seller">
                                            </Avatar>
                                            {
                                                !this.state.creatorID &&
                                                <IconButton className="edit-icon">
                                                    <img src={profilephoto ? EditYellowIc : AddYellowIc} alt="Edit" />
                                                    <input
                                                        accept="image/*"
                                                        id="upload-avatar-pic"
                                                        type="file"
                                                        data-test-id="uploadFile"
                                                        className="upload"
                                                        onChange={this.handleCreatorProfileImage}
                                                    />
                                                </IconButton>
                                            }
                                        </div>
                                        <div className="profile-description-wrap">
                                            <div className="name-wrap">
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                                    <span className="name">{businessName}</span>
                                                    <span><img src={group} alt="img" /></span>
                                                    <span style={{ marginLeft: "30px", padding: "2px 10px", borderRadius: "50px", background: "yellow" }}>@{username}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="following-section-seller new-insta-section">
                                <div className="following-followers-wrap">
                                    <div className="title-number-wrap"
                                        data-test-id="followers"
                                        >
                                        <img src={instagramIcon} alt="image" height={48} width={48} />
                                        <span className="number">{instagram_followers}</span>
                                    </div>
                                    <div className="title-number-wrap"
                                        data-test-id="following">
                                        <img src={youtubeIcon} alt="image" height={48} width={48} />
                                        <span className="number">{youtube_followers}</span>
                                    </div>
                                    <div className="title-number-wrap">
                                        <img src={FlixooIcon} alt="image" height={48} width={48} onClick={() => this.handleToggleModal()} />
                                        <span className="number">{this.state.flixoFollowerCount}</span>
                                    </div>
                                </div>
                                <div className="business-service-wrap">
                                </div>
                                <div className="business-content-wrap">
                                    <p>{bio || ""}</p>
                                </div>
                                <a href="#" className="decoration-none">
                                    <div className="profile-link">
                                        <img src={group_link} alt="" />
                                        {instagram_url}
                                    </div>
                                </a>
                                <div className="btn-wrap bio-btns">
                                    <Button
                                        className="custom-button primary-yellow seller-profile-yellow"
                                        onClick={this.handleNavigateServices}
                                    >
                                        Service offered
                                    </Button>
                                    <Button
                                        className="custom-button primary-yellow seller-profile-yellow"
                                    >
                                        Share Profile
                                    </Button>
                                    {
                                        !this.state.creatorID &&
                                        <Button
                                            className="custom-button primary-yellow seller-profile-yellow"
                                            onClick={this.handleNavigateEditProfile}
                                        >
                                            Edit Profile
                                        </Button>
                                    }
                                    {this.renderFollowUnfollowButton()}
                                </div>
                                <div className="highlights-heading">Visit my mall</div>
                                <div className="stories-highlights-wrap m-0">
                                    <div className="inner-wrap">
                                        {
                                            !this.state.creatorID &&
                                            <div className="story-outer" onClick={this.createMallNav}>
                                                <div className="story-new">
                                                    <IconButton className="edit-icon-story">
                                                        <img src={AddGrayIc} alt="Edit" />
                                                    </IconButton>
                                                </div>
                                                <div>
                                                    <p className="story-new-text">New</p>
                                                </div>
                                            </div>
                                        }
                                        {this.state.creatorMallData && this.state.creatorMallData.map((item: CreatorResponseData, index: number) => {
                                            return (
                                                <>
                                                    {
                                                        <StoryContainer data-test-id={`openStoryTestID${index}`}
                                                        >
                                                            {
                                                                <>
                                                                    <Avatar src={item.attributes.banner_thumbnail} onClick={() => this.navigateToCreateMall(item.id)} data-test-id={`imgUserTestID${index}`} className="profile-photo" />
                                                                    <span style={{ width: "110%", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>{item.attributes.name}</span>
                                                                </>
                                                            }
                                                        </StoryContainer>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                            <section className="tabs-section">
                                <div className="tabs-wrap">
                                    <Tabs
                                        value={this.state.tabValue}
                                        onChange={this.handleChange}
                                        className="custom-tabs-list"
                                        data-test-id="sellerProfileTab"
                                    >
                                        <Tab label="Posts" disableRipple />
                                        <Tab label="Reels" disableRipple />
                                        <Tab label="Content" disableRipple />
                                        <Tab label="Ambassador Shop" disableRipple />
                                    </Tabs>
                                    {this.renderPosts()}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                </MainStyleWrapper>
                <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.imageError} />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const truthyValue = (key: string | null | number) => {
    if (typeof key === 'string' && key.length > 0) {
        return key;
    } else {
        return "";
    }
};
const StoryContainer = styled(Box)({
    position: "relative",
    width: "72px",
    height: "72px",
    minWidth: "71px",
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #EB3AA7, #6649D9) border-box",
    borderRadius: "100%",
    border: "1px solid gray",
    marginRight: "30px",
    cursor: "pointer",
    '& .profile-photo': {
        width: "100%",
        height: "100%",
        borderRadius: "100%"
    }
});
const PostCardBox = styled(Box)({
    "& .postImageBox": {
        marginTop: "16px",
        maxWidth: "425px",
        width: "100%",
        maxHeight: "508px",
        borderRadius: "10px",
    },
    "& .postImage": {
        width: "100%",
        maxHeight: "508px",
        maxWidth: "510px",
        objectFit: "cover",
        borderRadius: "10px",
        height: "100%"
    },
    "& .postImage1": {
        width: "100% !important",
        maxHeight: "508px",
        borderRadius: "10px",
        height: "100% !important"
    },
    "& .nextArrowBtn": {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        zIndex: 2,
        padding: '4px',
        right: "15px"
    },
    "& .prevArrowBtn": {
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        color: '#fff',
        zIndex: 2,
        cursor: 'pointer',
        left: "15px",
        padding: '4px',
    },
    "& .postSlideBtn": {
        width: "20px",
        height: "20px"
    },
    "& .imageContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight: "508px"
    },
});

const CustomDialogContent = styled(DialogContent)({
    "&.MuiDialogContent-root":{
        flex: 0,
    },
    "& .tabName":{
        width:"150px"
    },
    "& .listBox":{
        padding:"20px",
        height:"100%",
        maxHeight:"475px",
        overflowY: "auto"
    },
    "& .listingInnerContainer":{
        display:"flex",
        gap:"18px",
        alignItems:"center",
        marginBottom:"25px"
    },
    "& .userAvatar":{
        width:"45px",
        height:"45px"
    },
    "& .fullNameTxt":{
        fontSize:"14px",
        fontWeight: 400,
        marginTop:"2px"
    },
    "& .userNameTxt":{
        fontSize:"16px",
        fontWeight: 600,
    }
});

const MainStyleWrapper = styled("span")({
    "& .followButton": {
        backgroundColor: "#f5ea16 !important",
        borderColor: " #f5ea16 !important",
        color: "#000 !important",
        fontSize: "14px !important",
        border: "1px solid transparent ",
        borderRadius: "20px",
        fontWeight: "600",
        textTransform: "none",
        minWidth: "115px",
        marginLeft:"5px"
    },
    "& .notFollowing":{
        backgroundColor:"white !important",
        border:"1px solid #f5ea16 !important"
    }
})

const paperDialogStyle = {
    borderRadius: 20,
    maxWidth: '468px',
    minHeight: '550px',
    width: '100%',
    maxHeight: "550px"
}
// Customizable Area End