// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  transferOpen: boolean;
  addProductOpen: boolean;
  tranferAmount: number;
  addPrice: number;
  isDrawer: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class SellerWalletController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      transferOpen: false,
      addProductOpen: false,
      tranferAmount: 0,
      addPrice: 0,
      isDrawer: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleTransferToogle = () => {
    this.setState({ transferOpen: !this.state.transferOpen });
  };

  handleAmountToogle = () => {
    this.setState({ addProductOpen: !this.state.addProductOpen });
  };

  handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let amountValue = event.target.value;
    const amountRegex = configJSON.amountRegex;
    if (amountRegex.test(amountValue)) {
      this.setState({ addPrice: Number(amountValue) });
    }
  };

  handleChangeTransferAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let transferValue = event.target.value;
    const transferRegex = configJSON.amountRegex;
    if (transferRegex.test(transferValue)) {
      this.setState({ tranferAmount: Number(transferValue) });
    }
  };

  toogleDrawer = () => {
    this.setState({isDrawer: !this.state.isDrawer})
  }
  // Customizable Area End
}
