import React from "react";
// Customizable Area Start
import {
    Avatar,
    Box,
    Chip,
    InputAdornment,
    TextField,
    Typography
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { noRecord, locationRecommended, recommendTick, liveIcon, liveTick, livenetwork, searchIcon } from "./assets";
let config = require('../../../framework/src/config');
import CreaterMallBoxComponent from "../../../components/src/CreaterMallBoxComponent";
import ProductCard from "../../../components/src/RecommendProductComponent";
import OrderCard from "../../../components/src/OrderBoxComponent";
import StarIcon from "@material-ui/icons/Star";
// Customizable Area End
import ViewAllProductsController, { BargainData, CategoryDetailsData, configJSON, CreatorMallData, LiveStreamData, RecommentedProductsData, TopSellingData } from "./ViewAllProductsController";
import { Catalogue } from "./LandingPageLatestController";
import Pagination from "@material-ui/lab/Pagination";
import MessageModalDownloadWeb from "../../../components/src/MessageModalDownload.web";

export class ViewAllProducts extends ViewAllProductsController {
    // Customizable Area Start
    renderNoDataFoundModal = () => {
        const { classes } = this.props;
        return (
            <NoDataFoundHomeImgBox>
                <img src={noRecord} className={classes.noDataHomeFoundImg} />
                <Typography><strong>No Record was Found</strong></Typography>
                <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
            </NoDataFoundHomeImgBox>
        )
    };

    renderCategories = () => {
        const { classes } = this.props;
        return (
            <MainContainer>
                <ScrollContainerHome>
                    {this.state.categoryData.length > 0 ? (
                        <CategoryContainerHome>
                            {
                                this.state.categoryData.map((category) => (
                                    <Category data-test-id="CategoriesDataId" key={category.id} onClick={() => this.handleNavigate("ViewAllProduct", "Category", category.id)}>
                                        <img src={config.baseURL + category.attributes.light_icon} alt={category.attributes.name} className="categoryImg" />
                                        <Typography className="categoryName" data-test-id="categoryId">{category.attributes.name}</Typography>
                                    </Category>
                                ))
                            }
                        </CategoryContainerHome>
                    ) : (
                        <NoDataFoundHomeImgBox>
                            <img src={noRecord} className={classes.noDataHomeFoundImg} />
                            <Typography><strong>No Record was Found</strong></Typography>
                            <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
                        </NoDataFoundHomeImgBox>
                    )}
                </ScrollContainerHome>
                <Pagination data-test-id="inventoryPagination" style={{ display: "flex", justifyContent: "center" }} count={this.state.pagination.total_pages} page={this.state.page} onChange={this.handleChangePagination} />
            </MainContainer>
        )
    };

    renderCreatarMall = () => {
        const { CreatorMallData } = this.state;
        return (
            <MainContainer>
                <ScrollContainerHome>
                    <CreaterContainerHome>
                        {
                            CreatorMallData?.map((data: CreatorMallData, index: number) => {
                                return (
                                    <>
                                        {
                                            data.attributes.creator_malls.data &&
                                            < CreaterMallBoxComponent
                                                data-test-id={`creatorMallId${index}`}
                                                handleCardClick={() => this.handleNavigate("ViewCreatorProfile", data.id)}
                                                onButtonClick={(event,id) => this.handleNavigateTo(event, "ViewMall", id)}
                                                title={data.attributes.full_name}
                                                description={data.attributes.my_bio}
                                                avatarUrl={data.attributes.profile_photo}
                                                images={data.attributes.creator_malls.data}
                                            />
                                        }
                                    </>
                                )
                            })
                        }
                    </CreaterContainerHome>
                </ScrollContainerHome>
                <Pagination data-test-id="inventoryPagination" style={{ display: "flex", justifyContent: "center" }} count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} />
            </MainContainer>
        )
    };

    renderRecommendProduct = () => {
        return (
            <MainContainer>
                {
                    this.state.recommendData.length > 0 ? (
                        <>
                            <ScrollRecommendContainerHome>
                                {this.state.recommendData.map((item: RecommentedProductsData) => (
                                    <RecommendBoxOuter>
                                        <RecommendBox>
                                            <ProductCard data-test-id="productDescription" products={item.catalogues} handleNavigate={() => this.handleNavigate("ProductDescription", item.account.id)} />
                                        </RecommendBox>
                                        <span className="navigateStore" onClick={() => this.handleNavigate("Store", item.account.id)} data-test-id={`navigateStoreTestId${item.account.id}`}>
                                            <StoreInfoContainer>
                                                <Avatar
                                                    style={{ width: "102px", height: "59px", borderRadius: "8px" }}
                                                    src={item.account?.background_image}
                                                    alt="Store Image"
                                                    variant="square"
                                                />
                                                <Box sx={{ marginLeft: "16px", flex: 1 }}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: 600, color: "#4E4E4E" }}
                                                    >
                                                        {item.account?.business_name}
                                                        <img
                                                            alt="image"
                                                            src={recommendTick}
                                                            style={{ width: "20.01px", height: "20px", color: "#007AFF", marginLeft: "8px" }}
                                                        />
                                                    </Typography>
                                                    <Typography
                                                        style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: 300, gap: "10px", color: "#6E6E6E" }}
                                                    >
                                                        <img
                                                            src={locationRecommended}
                                                            style={{ width: "18px", height: "18px" }}
                                                        />
                                                        3km, {item?.account?.address?.address_line_1}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", color: "#fbc02d", alignItems: "center" }}>
                                                    <StarIcon />
                                                    <Typography style={{ color: "#2E2E2E", marginLeft: "4px", fontSize: "14px", fontWeight: 500 }}>
                                                        4.5
                                                    </Typography>
                                                </Box>
                                            </StoreInfoContainer>
                                        </span>
                                    </RecommendBoxOuter>
                                ))}
                            </ScrollRecommendContainerHome >
                            <Pagination data-test-id="inventoryPagination" style={{ display: "flex", justifyContent: "center" }} count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} />
                        </>
                    ) : (this.renderNoDataFoundModal())
                }
            </MainContainer>
        )
    };

    renderLiveProduct = () => {
        return (
            <MainContainer>
                {
                    this.state.liveStreamData.length > 0 ? (
                        <>
                            <ScrollLiveContainerHome className="categoryScroll">
                                <LiveContainerHome>
                                    {this.state.liveStreamData.map((item: LiveStreamData, index: number) => (
                                        <Box style={{ width: "318px" }}>
                                            <LiveCard1 data-test-id={`LiveStreamingTestId${index}`} onClick={this.openLiveModal}>
                                                <img
                                                    style={{ borderRadius: "12px", width: "318px", height: "187px", objectFit: "cover", background: "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 35.52%, #000000 103.09%" }}
                                                    src={item.attributes.thumbnail}
                                                    alt="Live Stream"
                                                />
                                                <img src={liveIcon} alt="image" />
                                                <LiveBadge
                                                    label="10k"
                                                    icon={<img
                                                        alt="icon"
                                                        style={{ borderRadius: "50%", width: "14px", height: "11px" }}
                                                        src={livenetwork}
                                                    />
                                                    }
                                                />
                                                <CardFooter>
                                                    <Typography style={{ marginTop: "8px" }} variant="subtitle1" >
                                                        {item.attributes.title}
                                                    </Typography>
                                                    <Box style={{ width: "100%", display: "flex" }}>
                                                        <Avatar src={item.attributes.host_image} alt="Avatar" />
                                                        <UserInfo>
                                                            <Box style={{ display: "flex" }}>
                                                                <Typography variant="subtitle2">
                                                                    {item.attributes.host_name}
                                                                </Typography>
                                                                <img
                                                                    src={liveTick}
                                                                    style={{ height: "20px", width: "20.01px", color: "#007AFF", marginLeft: "8px" }}
                                                                />
                                                            </Box>
                                                            <Typography
                                                                variant="body2"
                                                            >
                                                                {"@" + item.attributes.host_name.replace(/\s+/g, "")}
                                                            </Typography>
                                                        </UserInfo>
                                                    </Box>
                                                </CardFooter>
                                            </LiveCard1>
                                        </Box>
                                    ))}
                                    <MessageModalDownloadWeb data-test-id="errorMessage" displayPopup={this.state.openLiveModal} closeErrorPopup={this.closeLiveModal} errorMessage={""}/>
                                </LiveContainerHome>
                            </ScrollLiveContainerHome>
                            <Pagination data-test-id="inventoryPagination" style={{ display: "flex", justifyContent: "center" }} count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} />
                        </>
                    ) : (this.renderNoDataFoundModal())
                }
            </MainContainer>
        )
    };

    renderTopSellingProduct = () => {
        return (
            <MainContainer>
                {
                    this.state.topSellingData.length > 0 ? (
                        <>
                            <ScrollTopSellingContainerHome className="categoryScroll">
                                <TopSellingContainerHome>
                                    {this.state.topSellingData.map((item: TopSellingData) => {
                                        const { tag, tagColor, tagTextColor } = this.getTagDetails(item.catalogue);
                                        return (
                                            <span data-test-id={`OnorederTestId${item.catalogue.id}`} onClick={() => { tag !== 'On Order' && this.handleNavigate("ProductDescription", item.catalogue.id) }}>
                                                <OrderCard
                                                    tag={tag}
                                                    tagColor={tagColor}
                                                    image={item.catalogue.catalogue_image}
                                                    productName={`${item.catalogue.title.substring(0, 8)}...`}
                                                    price={item.catalogue.selling_price}
                                                    originalPrice={item.catalogue.mrp}
                                                    storeImage={item.store.profile_photo}
                                                    storeName={`${item.store.business_name.substring(0, 15)}...`}
                                                    tagTextColor={tagTextColor}
                                                    handleOnOrderRequestModal={this.handleOnOrderRequestModal}
                                                    prodID={JSON.stringify(item.catalogue.id)}
                                                    isOnOrder={true}
                                                    isFromTopSelling={true}
                                                    handleTopSellingOnOrderReq={this.handleTopSellingOnOrderReq}
                                                    handleNavigate={() => this.handleNavigate("ProductDescription", item.catalogue.id)}
                                                    data-test-id="topSellingCompTestId"
                                                />
                                            </span>
                                        );
                                    })}
                                </TopSellingContainerHome>
                            </ScrollTopSellingContainerHome>
                            <Pagination style={{ display: "flex", justifyContent: "center" }} data-test-id="inventoryPagination" count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} />
                        </>
                    ) : (this.renderNoDataFoundModal())
                }
            </MainContainer>
        )
    };

    renderOnOrderSellingProduct = () => {
        return (
            <MainContainer>
                {
                    this.state.onOrderData.length > 0 ? (
                        <>
                            <OnOrderContainerHome className="categoryScroll">
                                <OnOrderContainerContainerHome>
                                    {this.state.onOrderData.map((item: Catalogue) => (
                                        <OrderCard
                                            tagColor={item.attributes.on_order ? "#FFEB3B" : ""}
                                            tag={item.attributes.on_order ? "On Order" : ""}
                                            productName={item.attributes.title.substring(0, 9) + '...'}
                                            image={item.attributes.product_images ? item.attributes.product_images[0].url : ''}
                                            storeImage={item.attributes?.host_image}
                                            isFromTopSelling={false}
                                            tagTextColor={item.attributes.on_order ? "#2E2E2E" : ""}
                                            storeName={item.attributes?.store?.business_name.substring(0, 15) + '...'}
                                            price={item?.attributes?.selling_price}
                                            originalPrice={item?.attributes?.mrp}
                                            handleOnOrderRequestModal={this.handleOnOrderRequestModal}
                                            handleTopSellingOnOrderReq={this.handleTopSellingOnOrderReq}
                                            isOnOrder={true}
                                            handleNavigate={() => this.handleNavigate("ProductDescription", item.id)}
                                            prodID={item.id}
                                            data-test-id="onOrderCompTestID"
                                        />
                                    ))}
                                </OnOrderContainerContainerHome>
                            </OnOrderContainerHome>
                            <Pagination count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} data-test-id="inventoryPagination" style={{ display: "flex", justifyContent: "center" }} />
                        </>
                    ) : (this.renderNoDataFoundModal())
                }
            </MainContainer>
        )
    };

    renderOnBargainSellingProduct = () => {
        return (
            <MainContainer>
                {
                    this.state?.bargainData?.length > 0 ? (
                        <>
                            <ScrollBargainContainerHome className="categoryScroll">
                                <BargainContainerHome>
                                    {this.state.bargainData.map((item: BargainData) => (
                                        <span data-test-id={`navigateProdTestId${item.id}`} onClick={() => this.handleNavigate("ProductDescription", item.id)}>
                                            <OrderCard
                                                image={item.attributes.product_images ? config.baseURL + item.attributes.product_images[0].url : ''}
                                                tagColor={item.attributes.bargain ? "#000000" : ""}
                                                originalPrice={item?.attributes?.mrp}
                                                price={item?.attributes?.selling_price}
                                                productName={item.attributes.title.substring(0, 9) + '...'}
                                                tag={item.attributes.bargain ? "Bargain" : ""}
                                                storeName={item.attributes?.store?.business_name.substring(0, 15) + '...'}
                                                tagTextColor={item.attributes.bargain ? "#FFFFFF" : ""}
                                                storeImage={item.attributes?.host_image}
                                                handleOnOrderRequestModal={this.handleOnOrderRequestModal}
                                                prodID={item.id}
                                                handleNavigate={() => this.handleNavigate("ProductDescription", item.id)}
                                                handleTopSellingOnOrderReq={this.handleTopSellingOnOrderReq}
                                                isFromTopSelling={false}
                                                isOnOrder={false}
                                            />
                                        </span>
                                    ))}
                                </BargainContainerHome>
                            </ScrollBargainContainerHome>
                            <Pagination data-test-id="inventoryPagination" count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} style={{ display: "flex", justifyContent: "center" }} />
                        </>
                    ) : (this.renderNoDataFoundModal())
                }
            </MainContainer>
        )
    };

    renderSubCategories = () => {
        const { classes } = this.props;
        return (
            <MainContainer>
                {
                    this.state.categoryDetailsData.length > 0 ? (
                        <>
                            <OnOrderContainerHome className="categoryScroll">
                                <OnOrderContainerContainerHome>
                                    {this.state.categoryDetailsData.map((item: CategoryDetailsData, index: number) => {
                                        return (
                                            <ContentBlockElement key={index}>
                                                <Box data-test-id={`productDescriptionID${index}`} onClick={() => this.handleNavigate("ProductDescription", item.id)} style={{ cursor: 'pointer' }}>
                                                    <img src={config.baseURL + item.attributes.product_images[0].url} style={{ height: '263px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', width: '190px', borderRadius: '10px', objectFit: 'cover' }} />
                                                </Box>
                                                <ContentBlockDesc>
                                                    <ContentBlockUser>
                                                        <Avatar src={item.attributes.host_image} />
                                                        <Typography style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.account.full_name}</Typography>
                                                    </ContentBlockUser>
                                                    <Description className={classes.description} style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.title}</Description>
                                                    <Description className={classes.description} style={{ fontSize: "12px", color: "#A5A4A4" }} >{item.attributes.description}</Description>
                                                    <Box sx={{ fontSize: '16px', fontWeight: 500, fontFamily: "Arial !important" }} >₹ {item.attributes.selling_price}</Box>
                                                </ContentBlockDesc>
                                            </ContentBlockElement>
                                        )
                                    })}
                                </OnOrderContainerContainerHome>
                            </OnOrderContainerHome>
                            <Pagination count={this.state.pagination?.total_pages} page={this.state.page} onChange={this.handleChangePagination} data-test-id="inventoryPagination" style={{ display: "flex", justifyContent: "center" }} />
                        </>
                    ) : (this.renderNoDataFoundModal())
                }
            </MainContainer>
        )
    };

    renderSearchBar = () => {
        return (
            <SearchBox>
                <SearchInventory
                    variant="outlined"
                    data-test-id="searchInventory"
                    value={this.state.searchProductText}
                    placeholder={configJSON.searchForProducts}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                data-test-id="searchIcon"
                                position="start"
                            >
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={searchIcon}
                                    alt="searchIcon"
                                />
                            </InputAdornment>
                        )
                    }}
                    onChange={this.handleSearchInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onKeyPress={this.handleKeyPress}
                />
            </SearchBox>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { urlParam } = this.state;
        let content;

        switch (urlParam) {
            case "Categories":
                content = this.renderCategories();
                break;
            case "CreatorMall":
                content = this.renderCreatarMall();
                break;
            case "RecommendProduct":
                content = this.renderRecommendProduct();
                break;
            case "LiveStreaming":
                content = this.renderLiveProduct();
                break;
            case "TopSelling":
                content = this.renderTopSellingProduct();
                break;
            case "OnOrder":
                content = this.renderOnOrderSellingProduct();
                break;
            case "Bargain":
                content = this.renderOnBargainSellingProduct();
                break;
            case "Category":
                content = this.renderSubCategories();
                break;
            default:
                content = null;
                break;
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                {this.renderSearchBar()}
                {content}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyles = {
    noDataHomeFoundImg: {
        maxWidth: '200px',
        width: '100%',
        animation: 'fadeIn 2s',
        MozAnimation: 'fadeIn 2s',
        WebkitAnimation: 'fadeIn 2s',
        OAnimation: 'fadeIn 2s',
        msAnimation: 'fadeIn 2s',
    }
};
const Description = styled(Typography)({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    height: '36px'
});
const ContentBlockUser = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: '8px'
});
const ContentBlockDesc = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '12px'
});
const ContentBlockElement = styled(Box)({
    position: "relative",
    maxWidth: '190px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    animation: 'fadeIn 2s',
    WebkitAnimation: 'fadeIn 2s',
    MozAnimation: 'fadeIn 2s',
    OAnimation: 'fadeIn 2s',
    msAnimation: 'fadeIn 2s'
});
const SearchInventory = styled(TextField)({
    width: "100%",
    borderRadius: '10px',
    border: '1px solid #FFF',
    background: '#FFF',
    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
    '& .MuiInput-underline:before': {
        borderBottom: 'none',
    },
    '&:focus': {
        outline: 'none',
    },
});
const SearchBox = styled(Box)({
    width: "700px",
    marginRight: "10px",
    marginTop: "130px",
    marginLeft: "40px"
});
const NoDataFoundHomeImgBox = styled(Box)({
    width: '100%',
    textAlign: 'center',
    '@media(min-width: 350px)': {
        marginLeft: '-8px'
    },
});
const MainContainer = styled(Box)({
    marginTop: "30px",
    marginBottom: "30px",
    padding: "0px 20px 20px 20px",
    minHeight: "85%",
    '@media(min-width: 1150px)': {
        marginTop: "50px",
    },
});
const ScrollContainerHome = styled(Box)({
    textAlign: "center" as "center",
    margin: "40px 0",
});
const CategoryContainerHome = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    gridTemplateColumns: "repeat(5 , 1fr)",
    paddingBottom: "20px"
});
const Category = styled(Box)({
    width: "270px",
    height: "140px",
    marginBottom: "40px",
    "& .categoryImg": {
        width: "100%",
        height: "140px",
        borderRadius: "8px"
    },
    "& .categoryName": {
        textAlign: "center",
        marginTop: "12px",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#4E4E4E"
    },
});
const CreaterContainerHome = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    gridTemplateColumns: "repeat(5 , 1fr)",
    paddingBottom: "20px"
});
const ScrollRecommendContainerHome = styled(Box)({
    marginTop: "70px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
});
const RecommendBoxOuter = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    whiteSpace: "nowrap",
    flexWrap: "nowrap",
    maxWidth: "574px",
    border: "1px solid #6E6E6E33",
    borderRadius: "12px"
});
const RecommendBox = styled(Box)({
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "scroll",
    whiteSpace: "nowrap",
    scrollbarWidth: "thin",
    gap: "16px",
    padding: "16px",
});
const StoreInfoContainer = styled(Box)({
    width: "542px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F6F6F6",
    borderRadius: "8px",
    marginBottom: "16px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    padding: "9px"
});
const ScrollLiveContainerHome = styled(Box)({
    marginTop: "20px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
});
const LiveContainerHome = styled(Box)({
    display: "flex",
    gap: "16px",
    padding: "2px",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
});
const LiveCard1 = styled(Box)({
    position: 'relative',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '12px',
    overflow: 'hidden',
});
const LiveBadge = styled(Chip)({
    position: "absolute",
    top: "12px",
    left: "10px",
    backgroundColor: "#e63946",
    color: "#fff",
    fontWeight: 700,
    width: "57px", height: "24px"
});
const CardFooter = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%"
});
const UserInfo = styled(Box)({
    marginLeft: "8px"
});
const ScrollTopSellingContainerHome = styled(Box)(() => ({
    marginTop: "20px",
    width: "100%",
    display: "flex",
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
}));
const TopSellingContainerHome = styled(Box)({
    display: "flex",
    gap: "20px",
    padding: "2px",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
});
const OnOrderContainerHome = styled(Box)({
    marginTop: "20px",
    width: "100%",
    display: "flex",
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
});
const OnOrderContainerContainerHome = styled(Box)({
    display: "flex",
    gap: "20px",
    padding: "2px",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
});
const ScrollBargainContainerHome = styled(Box)({
    marginTop: "20px",
    width: "100%",
    display: "flex",
    gap: "15px",
    paddingBottom: "20px",
    boxSizing: "border-box",
});
const BargainContainerHome = styled(Box)({
    display: "flex",
    gap: "20px",
    padding: "2px",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
});

export default withStyles(styled)(ViewAllProducts)
// Customizable Area End