// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTransferOpen: boolean;
  isAddOpen: boolean;
  transferAmt: number;
  addAmount: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class Cfflixoolivewallet0Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isTransferOpen: false,
      isAddOpen: false,
      transferAmt: 0,
      addAmount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleTransferToogle = () => {
    this.setState({ isTransferOpen: !this.state.isTransferOpen });
  };

  handleAmountToogle = () => {
    this.setState({ isAddOpen: !this.state.isAddOpen });
  };

  handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const regex = configJSON.amountRegex;
    if (regex.test(value)) {
      this.setState({ addAmount: Number(value) });
    }
  };

  handleChangeTransferAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    const regex = configJSON.amountRegex;
    if (regex.test(value)) {
      this.setState({ transferAmt: Number(value) });
    }
  };
  // Customizable Area End
}
